import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { CartCard, MetabaseDashboard } from '@/components/atomic/molecules';
import { Text } from '@/components/atomic/nuclei';
import {
  CART_LIST_TYPE,
  CART_STATUS,
  staticMediaStoreBaseURL
} from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';
import { eventTypeDashboard } from '@/services/metabase.service';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const CartList = ({
  cartCardListCommonProps,
  cartListType,
  eventDate,
  showControlToCollapseList,
  showCreateTenderAndDispatchBidsCTA,
  title,
  userEventCartList
}) => {
  const {
    setEditCartSummaryModal,
    setLoading,
    setShowToast,
    setUserEventCartDetails,
    userEventId,
    userInfo
  } = cartCardListCommonProps;
  const router = useRouter();
  const [isMobile] = useIsMobile();
  const [showCartCardList, setShowCartCardList] = useState(true);

  const onCartCardClick = ({ cartCard: { id, status } }) => {
    const pageName =
      status === CART_STATUS.ORDER.value
        ? PAGE_NAME.ORDERED_CART.label
        : PAGE_NAME.PLANNER_CART.label;
    return router.push(getPageURL({ pageName, pathParams: { cartId: id } }));
  };

  return (
    <div>
      <div className='flex items-center gap-2 mt-6'>
        <Text
          {...{
            className:
              'text-base md:text-2xl font-medium font-Montserrat flex self-center',
            content: title
          }}
        />
        {showControlToCollapseList && (
          <Image
            {...{
              alt: 'show hide details',
              className: `cursor-pointer transform ${
                showCartCardList ? 'rotate-0' : '-rotate-90'
              }`,
              height: 0,
              onClick: () => setShowCartCardList(!showCartCardList),
              src: `${staticMediaStoreBaseURL}/icons/down-arrow.svg`,
              style: { height: isMobile ? 12 : 14, width: isMobile ? 12 : 14 },
              width: 0
            }}
          />
        )}
      </div>
      <div>
        {showCartCardList &&
          userEventCartList.map((cartCard) => (
            <div
              id='cartCard'
              key={cartCard.cartNumber}
              onClick={() => onCartCardClick({ cartCard })}
            >
              <CartCard
                key={cartCard.id}
                {...{
                  cartCard,
                  cartListType,
                  eventDate,
                  router,
                  setEditCartSummaryModal,
                  setLoading,
                  setShowToast,
                  setUserEventCartDetails,
                  showCreateTenderAndDispatchBidsCTA,
                  userEventId,
                  userInfo
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

const ActiveCartList = ({ cartCardListCommonProps, userEventCartList }) => {
  const {
    setEditCartSummaryModal,
    setLoading,
    setShowToast,
    setUserEventCartDetails,
    userEventId,
    userInfo
  } = cartCardListCommonProps;
  const router = useRouter();
  const onCartCardClick = ({ id }) =>
    router.push(
      getPageURL({
        pageName: PAGE_NAME.PLANNER_CART.label,
        pathParams: { cartId: id }
      })
    );

  return userEventCartList.map((cartCard) => (
    <div
      id='cartCard'
      key={cartCard.cartNumber}
      onClick={() => onCartCardClick(cartCard)}
    >
      <CartCard
        key={cartCard.id}
        {...{
          cartCard,
          cartListType: CART_LIST_TYPE.EVENT,
          router,
          setEditCartSummaryModal,
          setLoading,
          setShowToast,
          setUserEventCartDetails,
          userEventId,
          userInfo
        }}
      />
    </div>
  ));
};

const filterActiveCart = ({ status, isArchived }) =>
  [CART_STATUS.DRAFT.value, CART_STATUS.COLLABORATE.value].includes(status) &&
  !isArchived;
const filterArchivedCart = ({ isArchived }) => isArchived;
const filterOrderedCart = ({ status }) =>
  [CART_STATUS.ORDER.value].includes(status);

const EventCartListsOrganism = ({
  setEditCartSummaryModal,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventCartDetails
}) => {
  const { userEventInformation: userEvent, userEventCartList } =
    userEventCartDetails;
  const { user: userInfo = {}, userEventDetails: [{ userEventId } = {}] = [] } =
    userEvent;

  if (userEventCartList.length === 0) {
    eventTypeDashboard.dashboard.params = {
      event_id: userEventId,
      user_id: userInfo?.id
    };
    return (
      <MetabaseDashboard {...{ dashboard: eventTypeDashboard.dashboard }} />
    );
  }

  const cartCardListCommonProps = {
    noCartFoundTitle: 'No Cart Found',
    setEditCartSummaryModal,
    setLoading,
    setShowToast,
    setUserEventCartDetails,
    userEventId,
    userInfo
  };

  const activeEventCarts = userEventCartList.filter(filterActiveCart);
  const archivedCarts = userEventCartList.filter(filterArchivedCart);
  const orderedCarts = userEventCartList.filter(filterOrderedCart);

  return (
    <div>
      <ActiveCartList
        {...{
          cartCardListCommonProps,
          userEventCartList: activeEventCarts
        }}
      />
      {orderedCarts.length > 0 && (
        <CartList
          {...{
            cartCardListCommonProps,
            cartListType: CART_LIST_TYPE.ORDER,
            showControlToCollapseList: true,
            showCreateTenderAndDispatchBidsCTA: false,
            title: 'Event Order List',
            userEventCartList: orderedCarts
          }}
        />
      )}
      {archivedCarts.length > 0 && (
        <CartList
          {...{
            cartCardListCommonProps,
            cartListType: CART_LIST_TYPE.ARCHIVE,
            showControlToCollapseList: true,
            title: 'Archived Carts',
            userEventCartList: archivedCarts
          }}
        />
      )}
    </div>
  );
};

export default EventCartListsOrganism;
