import { useRouter } from 'next/router';

import {
  EPICBrief,
  EventCartListSideBarBody,
  EventCartListSideBarHead
} from '@/components/atomic/atoms';
import { useIsMobile } from '@/lib/screenResolution';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const EventCartListSidebar = ({ setShowToast, userEvent }) => {
  const router = useRouter();

  const [isMobile] = useIsMobile();

  const displayHostEventProfile = () => {
    router.push(
      getPageURL({
        excludeBaseUrl: false,
        pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
        pathParams: {
          userEventId: userEvent.userEventDetails[0].userEventId
        }
      })
    );
  };

  return (
    <div className='md:w-108 bg-white px-6 py-4 md:pl-10 md:pr-10 md:pt-5 flex flex-col gap-3'>
      <EventCartListSideBarHead
        {...{
          displayHostEventProfile,
          isMobile,
          userEvent
        }}
      />
      <EventCartListSideBarBody
        {...{
          router,
          userEvent
        }}
      />
      <div className='mt-2 mb-4 border-t border-gray'></div>
      <EPICBrief
        {...{
          setShowToast,
          userEvent
        }}
      />
    </div>
  );
};

export default EventCartListSidebar;
