import _ from 'lodash';
import { useRouter } from 'next/router';
import { memo, useMemo, useState } from 'react';
import { InfiniteHits, useSearchBox } from 'react-instantsearch';

import {
  ListCard,
  NoResultsBoundary,
  PageNotFound,
  PreviewCard,
  TabMenu
} from '@/components/atomic/atoms';
import { NavigationSearchBar } from '@/components/atomic/molecules';
import { CloseIcon } from '@/components/atomic/nuclei';
import { isDevelopmentOrRNDEnv } from '@/config/common';
import {
  ALL_TAB_CATEGORY_CONFIG,
  DEFAULT_TAB_CATEGORY,
  NAVIGATION_INDEX,
  TAB_CATEGORY_ATTRIBUTE,
  TAB_CATEGORY_CONFIG_LIST
} from '@/config/searchkit/navigation';
import { transformSearchResultList } from '@/services/navigation.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const SearchResultsPanel = ({
  memoizedHandleTransformItems,
  selectedPreviewCard,
  setSelectedPreviewCard,
  showResults
}) => {
  const { query } = useSearchBox();
  const showListAndPreviewCard = !_.isEmpty(selectedPreviewCard);
  return (
    <div className='flex justify-center gap-6 px-6'>
      <NoResultsBoundary
        fallback={
          <PageNotFound
            {...{
              className: 'py-20',
              description: `Sorry, we couldn't find any matches for "${query}"`,
              iconHeight: 445,
              iconWidth: 625,
              notFoundImage: 'no-search-result.svg',
              title: 'No search result'
            }}
          />
        }
      >
        <InfiniteHits
          {...{
            classNames: {
              item: 'p-0 border-none shadow-none',
              list: 'flex flex-col gap-4',
              loadMore: showResults
                ? 'border-none hover:bg-none text-brand font-medium'
                : 'hidden',
              root: 'flex flex-col flex-1'
            },
            hitComponent: ({ hit: { listCard, previewCard } }) =>
              showListAndPreviewCard && (
                <ListCard
                  {...{
                    isSelectedResult:
                      listCard.entityId === selectedPreviewCard.entityId,
                    listCard,
                    previewCard,
                    setSelectedPreviewCard
                  }}
                />
              ),
            showPrevious: false,
            transformItems: memoizedHandleTransformItems,
            translations: {
              showMoreButtonText: 'Show more'
            }
          }}
        />
        {showListAndPreviewCard && (
          <PreviewCard
            key={selectedPreviewCard.entityId}
            {...{ selectedPreviewCard }}
          />
        )}
      </NoResultsBoundary>
    </div>
  );
};

const NavigationBodyOrganism = memo(
  ({
    query,
    searchableAttribute,
    selectedTabMenu,
    setQuery,
    setSearchableAttribute,
    setSelectedTabMenu,
    setShowResults,
    showResults
  }) => {
    const { refine: searchRefine } = useSearchBox({ attribute: 'query' });

    const router = useRouter();

    const [selectedPreviewCard, setSelectedPreviewCard] = useState(null);

    const handleTransformItems = (newHits) => {
      const transformedHits = transformSearchResultList({
        searchResults: newHits
      });

      if (transformedHits.length > 0) {
        const firstPreviewCard = transformedHits[0].previewCard;
        setSelectedPreviewCard(firstPreviewCard);
      } else {
        setSelectedPreviewCard(null);
      }
      return transformedHits;
    };

    const memoizedHandleTransformItems = useMemo(
      () => handleTransformItems,
      []
    );

    return (
      <div className='relative'>
        <div className='sticky top-0 bg-white pt-4 flex flex-col justify-center items-center h-36 text-lg font-medium'>
          <div className='absolute top-8 right-5 z-10'>
            <CloseIcon
              {...{
                height: 32,
                icon: 'close-red-icon.svg',
                onClick: () => {
                  // TODO: Faizan/Hardik - use next/router. Do not use window.*
                  if (window.navigation.canGoBack) {
                    router.back();
                  } else {
                    router.replace(
                      getPageURL({ pageName: PAGE_NAME.WELCOME.label })
                    );
                  }
                },
                width: 32
              }}
            />
          </div>
          <TabMenu
            {...{
              allTabMenuConfig: ALL_TAB_CATEGORY_CONFIG,
              attribute: TAB_CATEGORY_ATTRIBUTE,
              defaultTabCategory: DEFAULT_TAB_CATEGORY,
              index: NAVIGATION_INDEX,
              onTabMenuSwitch: () => {
                searchRefine('');
              },
              selectedTabMenu,
              setQuery,
              setSelectedTabMenu,
              setShowResults,
              showTabMenuCount: isDevelopmentOrRNDEnv,
              tabCategoryConfigList: TAB_CATEGORY_CONFIG_LIST
            }}
          />
          <NavigationSearchBar
            {...{
              query,
              searchableAttribute,
              selectedTabMenu,
              setQuery,
              setSearchableAttribute,
              setShowResults
            }}
          />
        </div>
        {showResults ? (
          <SearchResultsPanel
            {...{
              memoizedHandleTransformItems,
              selectedPreviewCard,
              setSelectedPreviewCard,
              showResults
            }}
          />
        ) : (
          <PageNotFound
            {...{
              alt: 'entity not found',
              className: 'py-20',
              iconHeight: 445,
              iconWidth: 625,
              notFoundImage: 'entity-not-found.svg',
              subDivClassName: '',
              title: 'Find What You’re Looking For'
            }}
          />
        )}
      </div>
    );
  }
);

NavigationBodyOrganism.displayName = 'NavigationBodyOrganism';

export default NavigationBodyOrganism;
