import * as HttpService from 'services/http.service';
import { POST_METABASE_DASHBOARD_SIGNED_URL } from 'services/url.service';

export const eventTypeDashboard = {
  dashboard: {
    description:
      'Use this planner board to plan your perfect event. Plan + Product + Partner + Price = a winning quote',
    params: { event_id: null, user_id: null },
    title: 'Planner EventType DashBoard',
    queryParameters: {
      questionNo: 333,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  }
};

export const plannerEventPipelineDashBoard = {
  dashboard: {
    title: 'Planner Event Pipeline DashBoard',
    description: 'where are you',
    params: { ops_user_id: null },
    queryParameters: {
      questionNo: 595,
      theme: 'transparent',
      bordered: false,
      titled: false
    }
  }
};

export const generateMetabaseDashboardSignedURL = (data) =>
  HttpService.postWithAuth(POST_METABASE_DASHBOARD_SIGNED_URL(), data);
