import {
  OrderPriceSummaryWithDetailMobile,
  OrderPriceSummaryWithDetailWeb
} from '@/components/atomic/molecules';
import { DEVICE_SURFACE } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import { GetUiDeviceSurface } from '@/lib/screenResolution';
import { calculatePreVatPrice } from '@/services/order.service';

const OrderPriceSummaryWithDetailOrganism = ({
  orderDetail,
  termsAndConditions
}) => {
  const surface = GetUiDeviceSurface();

  const PriceSummaryDetailMap = {
    [DEVICE_SURFACE.MOBILE]: OrderPriceSummaryWithDetailMobile,
    [DEVICE_SURFACE.WEB]: OrderPriceSummaryWithDetailWeb
  };

  const PriceSummaryDetail = PriceSummaryDetailMap[surface];

  const paymentSummary = orderDetail?.derivedValues?.paymentsSummary;
  const paymentBreakdownItemList = [
    {
      label: 'Grand Total (AED)',
      value: parseFormatPriceValueFromAPI(orderDetail?.orderTotal)
    },
    {
      label: 'Advance Amount (AED)',
      value: parseFormatPriceValueFromAPI(paymentSummary?.netPaymentReceived)
    },
    {
      label: 'Due Amount (AED)',
      value: parseFormatPriceValueFromAPI(paymentSummary?.paymentDueAmount)
    }
  ];

  const paymentInfo =
    paymentSummary?.paymentDueAmount > 0 ? paymentBreakdownItemList : null;

  const priceInfo = {
    ...orderDetail,
    preVatAmount: calculatePreVatPrice(orderDetail)
  };

  return (
    <PriceSummaryDetail
      {...{
        paymentInfo,
        priceInfo,
        termsAndConditions
      }}
    />
  );
};

export default OrderPriceSummaryWithDetailOrganism;
