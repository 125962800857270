import Link from 'next/link';

import { CartItemCTA, CartItemSummary } from '@/components/atomic/atoms';
import { CartItemDetail, LineItemMedia } from '@/components/atomic/molecules';
import { Button, Text } from '@/components/atomic/nuclei';
import { CART_ITEM_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { getProductPartnerList } from '@/services/cart.plan.service';
import { getProductDetailPageURL } from '@/services/hostPortal.service';

const PriceAwaitedCartItemList = ({
  cartId,
  cartItem,
  cartItemIdInViewEditMode,
  isCartEditable,
  onClickCloneCartItem,
  onDeleteCartItemMedia,
  setCartItemIdForUploadMediaModal,
  setCartItemIdInViewEditMode,
  setShowToast,
  updateCartItemMediaSortOrder,
  verifyAndSetItemInRequestToDeleteMode,
  userId
}) => {
  const { id, itemMediaForCarousel, product, showProductMedia } = cartItem;

  const { name, slug } = product;
  const unlockCTA = cartItemIdInViewEditMode !== id;

  return (
    <div className='w-full'>
      <div className='flex gap-5 text-sm font-light py-3 border-b border-platinum bg-lightgray px-4 rounded-t-lg'>
        <div className='flex-1 flex gap-3 self-center'>
          <LineItemMedia
            {...{
              isCartEditable,
              itemId: id,
              itemMediaForCarousel,
              itemParentId: cartId,
              onDeleteCartItemMedia,
              productName: name,
              setCartItemIdForUploadMediaModal,
              setShowToast,
              showProductMedia,
              updateCartItemMediaSortOrder,
              userId
            }}
          />
          <Link
            className='self-center'
            href={getProductDetailPageURL({ slug })}
          >
            <Text
              {...{
                className: 'self-center flex-1 flex',
                content: name
              }}
            />
          </Link>
        </div>
        <CartItemSummary {...{ cartItem }} />
        <div className='w-40 self-center flex gap-3'>
          <Button
            {...{
              className:
                'bg-gradient-to-r h-8 from-light-orange via-dark-orange to-rose text-white px-2 py-2 rounded-lg text-sm font-medium',
              iconGroupClassName: 'gap-0',
              iconHeight: 15,
              iconPosition: 'Left',
              iconURL: `${staticMediaStoreBaseURL}/icons/view-icon.svg`,
              iconWidth: 15,
              label: 'View',
              onClick: () => unlockCTA && setCartItemIdInViewEditMode(id),
              width: 'w-20'
            }}
          />
          <CartItemCTA
            {...{
              className: 'self-center',
              onClick: () => onClickCloneCartItem({ cartItemId: id }),
              type: 'cloneCartItem'
            }}
          />
          <CartItemCTA
            {...{
              className: `self-center ${
                isCartEditable && cartItemIdInViewEditMode !== id
                  ? ''
                  : 'pointer-events-none opacity-40'
              }`,
              onClick: () =>
                isCartEditable &&
                unlockCTA &&
                verifyAndSetItemInRequestToDeleteMode(id),
              type: 'delete'
            }}
          />
        </div>
      </div>
    </div>
  );
};

const FinalizePriceCTA = ({
  acceptionPendingRFQItemsWithPrice,
  onClickFinalizePrice
}) => (
  <div className='flex justify-end float-right my-2 mx-4'>
    <Button
      {...{
        className:
          'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white px-4 py-2 rounded-lg text-sm font-medium',
        disabled: !acceptionPendingRFQItemsWithPrice.length,
        label: 'Finalize Price',
        onClick: () =>
          acceptionPendingRFQItemsWithPrice.map((item) =>
            onClickFinalizePrice({
              id: item.id,
              status: CART_ITEM_STATUS.ACCEPTED.value
            })
          ),
        width: 'w-auto'
      }}
    />
  </div>
);

const PriceAwaitedWIPCartItemOrganism = ({
  itemList = [],
  itemListComponentCommonProps,
  onClickCloneCartItem,
  onClickFinalizePrice
}) => {
  const {
    cartId,
    cartItemIdInViewEditMode,
    cartItemRef,
    cartItemViewEditMode,
    isCartEditable = true,
    onDeleteCartItemMedia,
    saveCartItemChanges,
    setCartItemIdForUploadMediaModal,
    setCartItemIdInViewEditMode,
    setCartItemViewEditMode,
    setShowToast,
    unSavedChangesErrorMessage,
    updateCartItemDetail,
    updateCartItemMediaSortOrder,
    userId,
    verifyAndSetItemInRequestToDeleteMode,
    viewCartItemInOpsPortal
  } = itemListComponentCommonProps;

  const acceptionPendingRFQItemsWithPrice = itemList.filter(
    ({ unitListedPrice, status }) =>
      status !== CART_ITEM_STATUS.ACCEPTED.value &&
      typeof unitListedPrice === 'number' &&
      unitListedPrice > 0
  );
  return (
    <div className='border border-red-500 rounded-lg'>
      {itemList.map((item) => (
        <div key={item.id}>
          <PriceAwaitedCartItemList
            {...{
              cartId,
              cartItem: item,
              cartItemIdInViewEditMode,
              cartItemRef,
              isCartEditable,
              onClickCloneCartItem,
              onDeleteCartItemMedia,
              saveCartItemChanges,
              setCartItemIdForUploadMediaModal,
              setCartItemIdInViewEditMode,
              setShowToast,
              updateCartItemMediaSortOrder,
              userId,
              verifyAndSetItemInRequestToDeleteMode,
              viewCartItemInOpsPortal
            }}
          />
          {cartItemIdInViewEditMode === item.id && (
            <CartItemDetail
              {...{
                cartItem: item,
                cartItemViewEditMode,
                isCartEditable,
                productPartnersDropdownList: getProductPartnerList(
                  item.product.productPartners
                ),
                setCartItemIdInViewEditMode,
                setCartItemViewEditMode,
                unSavedChangesErrorMessage,
                updateCartItemDetail
              }}
            />
          )}
        </div>
      ))}
      {isCartEditable && (
        <FinalizePriceCTA
          {...{
            acceptionPendingRFQItemsWithPrice,
            onClickFinalizePrice
          }}
        />
      )}
    </div>
  );
};

export default PriceAwaitedWIPCartItemOrganism;
