import * as HttpService from '@/services/http.service';
import {
  ADD_TO_CART,
  VERIFY_CART_NUMBER_FOR_ADD_ITEM
} from '@/services/url.service';

export const cartNumberVerificationStatuses = {
  FAILED: 'FAILED',
  UNCHECKED: 'UNCHECKED',
  VERIFIED: 'VERIFIED'
};

export const verifyCartNumberForAddItemAPI = ({ cartNumber }) =>
  HttpService.getWithAuth(VERIFY_CART_NUMBER_FOR_ADD_ITEM({ cartNumber }));

export const addToCartAPI = ({ cartId, userCartId, data }) =>
  HttpService.postWithAuth(ADD_TO_CART({ cartId, userCartId }), data);

export const addToCart = async ({
  activePlannerCart,
  productDetail,
  setShowToast,
  updateActivePlannerCart
}) => {
  const { id: cartId, userCartId } = activePlannerCart;
  const { entity, message, status } = await addToCartAPI({
    cartId,
    userCartId,
    data: { ...productDetail, userCartId }
  });
  if (status && entity.id) {
    updateActivePlannerCart({
      ...activePlannerCart,
      cartItemCount: entity.cartItemCount
    });
  }
  setShowToast({
    message,
    show: true,
    status
  });
};

export const addToCartFromSelectCartModal = async ({
  closeActionModal,
  productDetail,
  setInvalidMessage: setInvalidCartNumberMessage,
  updateActivePlannerCart,
  verifiedCartDetail
}) => {
  const { id: cartId, userCartId } = verifiedCartDetail;
  const { entity, message, status } = await addToCartAPI({
    cartId,
    userCartId,
    data: { ...productDetail, userCartId }
  });
  if (status && entity.id) {
    updateActivePlannerCart({
      ...verifiedCartDetail,
      cartItemCount: entity.cartItemCount
    });
    closeActionModal();
  } else {
    setInvalidCartNumberMessage(message);
  }
};

export const verifyCartNumberForAddItem = async ({
  inputValue: cartNumber,
  setInvalidMessage: setInvalidCartNumberMessage,
  setTargetEntity: setVerifiedCartDetail,
  setVerificationStatus: setCartVerificationStatus
}) => {
  const { FAILED, VERIFIED } = cartNumberVerificationStatuses;
  const { status, entity } = await verifyCartNumberForAddItemAPI({
    cartNumber
  });
  if (status && entity.isVerified) {
    setVerifiedCartDetail({ ...entity, cartNumber });
    setCartVerificationStatus(VERIFIED);
  } else {
    const { error: { errorMessage } = {} } = entity;
    const apiErrorMessage = errorMessage
      ? 'Cart status Invalid'
      : 'Cart number Invalid.';
    setCartVerificationStatus(FAILED);
    setInvalidCartNumberMessage(apiErrorMessage);
  }
};
