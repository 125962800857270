import Image from 'next/image';

import {
  HostEventStatistics,
  LetsPlanFirstEvent
} from '@/components/atomic/atoms';
import {
  Avatar,
  Button,
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import {
  HOST_INTERACTION_STATUS,
  staticMediaStoreBaseURL
} from '@/config/common';
import { convertToShortMonthDateFormat } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';

const HostNameWithVerifyIcon = ({ name, isMobileVerified }) => (
  <div className='flex gap-2'>
    <Text
      {...{
        className: 'text-nero text-xl font-semibold text-white truncate',
        content: name
      }}
    />
    <Image
      alt='host status'
      className='relative -top-2'
      height={0}
      priority={true}
      src={`${staticMediaStoreBaseURL}/icons/${isMobileVerified ? 'host-verified-icon.svg' : 'host-not-verified-icon.svg'}`}
      style={{
        height: 20,
        width: 20
      }}
      width={0}
    />
  </div>
);
const HostProfileSidebar = ({
  hostProfile: { user = {}, hostEventStatistics = {} } = {}
}) => {
  const {
    createdAt,
    hostCreditEntity,
    hostNumber,
    interactionStatus,
    isMobileVerified,
    name,
    updatedAt
  } = user;
  const { name: hostCreditEntityName = null } = hostCreditEntity ?? {};
  const showLetsPlanFirstEvent =
    interactionStatus === HOST_INTERACTION_STATUS.NEW.value;

  return (
    <div className='md:w-108 fixed h-screen z-30 bg-host-event-sidebar bg-no-repeat bg-cover px-6 py-4 md:pl-10 md:pr-10 md:pt-5 flex flex-col gap-3'>
      <Image
        alt='hafla-logo'
        className='rounded-lg'
        height={0}
        priority={true}
        src={`${staticMediaStoreBaseURL}/icons/hafla-white-logo.svg`}
        style={{
          height: 40,
          width: 125
        }}
        width={0}
      />
      <div className='flex items-center gap-4 mt-4'>
        <div className='w-[100px] h-[100px] rounded-lg'>
          <Avatar
            {...{
              badgeStyle: 'bg-white',
              name,
              textStyle: 'text-brand-gradient'
            }}
          />
        </div>
        <div className='flex flex-col gap-1'>
          <HostNameWithVerifyIcon {...{ name, isMobileVerified }} />
          {hostCreditEntityName && (
            <Text
              {...{
                className: 'text-base text-white font-medium',
                content: hostCreditEntityName
              }}
            />
          )}
        </div>
      </div>
      <div className='flex items-center justify-between'>
        <Text
          {...{
            className: 'text-xs text-white font-medium',
            content: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`
          }}
        />
        <div className='flex gap-2'>
          <Text
            {...{
              className: `${HOST_INTERACTION_STATUS[interactionStatus]?.className} rounded-lg px-2 py-1 text-sm font-medium self-center items-center flex`,
              content: HOST_INTERACTION_STATUS[interactionStatus]?.label
            }}
          />
          <TextWithIcon
            {...{
              className:
                'bg-white border-neutral border rounded-lg px-3 py-1 self-center items-center flex',
              icon: 'event-red-icon.svg',
              label: parseNumberedLabel(hostNumber),
              labelStyle: 'text-sm font-medium text-dim-gray'
            }}
          />
        </div>
      </div>
      <div className='flex flex-col gap-4 justify-center items-center fixed bottom-20 w-[26rem] h-[280px]'>
        {showLetsPlanFirstEvent ? (
          <LetsPlanFirstEvent
            {...{
              createdAt,
              labelClassName: 'text-white'
            }}
          />
        ) : (
          <HostEventStatistics {...{ createdAt, hostEventStatistics }} />
        )}

        <div className='flex flex-col gap-2 w-full text-white'>
          <Button
            {...{
              className: 'border border-white rounded-lg py-3 font-medium',
              label: 'Apply For New Credit'
            }}
          />
          <div className='flex gap-1 font-medium'>
            <TextKeyValuePair
              {...{
                className: 'flex-row',
                label: 'Maamar Zaine ',
                labelClass: 'text-xs font-semibold gap-1',
                value: 'request for ',
                valueClassName: 'text-xs'
              }}
            />
            <TextKeyValuePair
              {...{
                className: 'flex-row',
                label: ' AED 120,000 ',
                labelClass: 'text-xs font-semibold',
                value: ' has been rejected.',
                valueClassName: 'text-xs'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostProfileSidebar;
