import { Fragment } from 'react';

import { AddUserEventBanner, UserEventCard } from '@/components/atomic/atoms';
import { Button, Text } from '@/components/atomic/nuclei';

const UserEventCardListHeader = () => (
  <div className='flex w-full justify-between'>
    <Text
      {...{
        className: 'text-2xl text-nero font-medium',
        content: 'Event List'
      }}
    />
    <Button
      {...{
        className:
          'bg-brand-gradient text-white font-medium text-sm py-3 px-4 rounded-lg hidden',
        label: 'Add Event',
        width: 'w-28',
        disabled: true
      }}
    />
  </div>
);

const UserEventCardList = ({
  userEvents: { rows: userEventList = [] } = {}
}) => (
  <Fragment>
    <div className='flex w-full flex-col gap-4 mt-4 cursor-pointer'>
      {userEventList.map((userEvent, index) => (
        <UserEventCard
          key={index}
          {...{ userEvent }}
        />
      ))}
    </div>
    {userEventList.length < 1 && <AddUserEventBanner />}
  </Fragment>
);

const UserEventCardContainer = ({ userEvents }) => (
  <div className='flex flex-col flex-1 px-6 py-5 items-baseline bg-neutral min-h-[91vh] ml-[31rem]'>
    <UserEventCardListHeader />
    <UserEventCardList {...{ userEvents }} />
  </div>
);

export default UserEventCardContainer;
