import Link from 'next/link';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';

import { DatePicker } from '@/components/atomic/atoms';
import {
  Button,
  CloseIcon,
  Dropdown,
  InputField,
  Text,
  TextKeyValuePair
} from '@/components/atomic/nuclei';
import { CART_STATUS } from '@/config/common';
import {
  convertToShortMonthDateFormat,
  getFormattedDate,
  parseDateWithMoment,
  subtractDaysFromDate
} from '@/lib/time';
import useYupValidator from '@/lib/yupValidator';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';
import {
  onSubmitCartChanges,
  updateCart
} from '@/services/userEventCartList.service';

const ActionButton = ({ buttonName, className, onClick }) => (
  <Button
    {...{
      className: `py-3 px-2 text-sm font-medium self-end rounded-lg ${className}`,
      label: buttonName,
      onClick,
      width: 'w-32'
    }}
  />
);

const ActionButtonPair = ({ actionButtonPairList }) => (
  <div className='flex gap-2 justify-center'>
    {actionButtonPairList.map(({ buttonName, className, onClick }, id) => (
      <ActionButton
        key={id}
        {...{
          buttonName,
          className,
          onClick
        }}
      />
    ))}
  </div>
);

const LabelValuePair = ({
  className = 'flex w-1/2 gap-2 flex-col border-b border-platinum pb-2 my-5',
  label,
  value
}) => (
  <TextKeyValuePair
    {...{
      className,
      label,
      labelClass: 'text-nero',
      spaceTop: '',
      value,
      valueClassName: 'text-dim-gray'
    }}
  />
);

const DatePickerField = ({
  className = 'flex w-1/2 gap-2 flex-col pb-2 my-5',
  dbName,
  errors,
  label,
  minDate,
  selectedDate,
  setSelectedDate,
  setValue
}) => (
  <div className={className}>
    <Text
      className='text-nero'
      content={label}
    />
    <DatePicker
      {...{ dbName, selectedDate, setSelectedDate, setValue, errors, minDate }}
    />
  </div>
);

const LabelInputPair = ({
  className,
  content,
  dbName,
  defaultValue,
  errors,
  register
}) => (
  <div className='flex gap-2 flex-col border-b border-platinum pb-2 my-5'>
    <Text
      {...{
        className: 'text-nero',
        content
      }}
    />
    <InputField
      {...{
        className,
        dbName,
        defaultValue,
        errors,
        id: dbName,
        name: dbName,
        register: { ...register(dbName) },
        type: 'text'
      }}
    />
  </div>
);

const CartStatusChangeValidationErrorMessage = ({
  userEventId,
  setEditCartSummaryModal
}) => (
  <div className='bg-error-base-bg text-sm font-medium p-3 rounded mb-5'>
    <Text
      {...{
        className: 'text-error-base',
        content: `⚠️ The respective Event Profile has mandatory fields with dummy values or empty. Please check and fill in all the required details to proceed`,
        HtmlTag: 'span'
      }}
    />
    <Link
      href={getPageURL({
        pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
        pathParams: { userEventId }
      })}
    >
      <Text
        className='text-blue px-1 cursor-pointer'
        content='click to view event details'
        HtmlTag='span'
        onClick={() => {
          setEditCartSummaryModal({ showModal: false, cartSummary: null });
        }}
      />
    </Link>
  </div>
);

const CartSummaryModal = ({
  cartSummary,
  eventDate,
  setEditCartSummaryModal,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId,
  userEventInformation,
  userId
}) => {
  const {
    cartName,
    cartNumber,
    createdAt,
    deliveryDate,
    id,
    pickupDate,
    status,
    updatedAt
  } = cartSummary || {};
  const { userEventDetails: [{ allocationStatus } = {}] = [] } =
    userEventInformation;
  const [selectedDate, setSelectedDate] = useState(null);
  const [cartStatusChangeValidationError, setCartStatusChangeValidationError] =
    useState(null);

  const dateOfEvent = eventDate && parseDateWithMoment(eventDate);
  const twoDaysBeforeDateOfEvent = subtractDaysFromDate({
    date: eventDate,
    daysToSubtract: 2
  });

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue
  } = useForm({
    resolver: useYupValidator(
      yup.object().shape({
        cartName: yup
          .string()
          .trim()
          .min(4, 'Cart Name must be at least 4 characters.')
          .max(128, 'Cart Name has a maximum limit of 128 characters.')
          .matches(/^[^\uD800-\uDFFF]+$/g, 'Emojis are not allowed')
          .required('Cart Name is required.'),
        pickupDate: yup
          .date()
          .min(
            yup.ref('deliveryDate'),
            'Pickup Date must be greater than or equal to Delivery Date.'
          )
          .nullable()
      })
    ),
    defaultValues: {
      cartName,
      cartNumber,
      createdAt,
      deliveryDate: deliveryDate ? parseDateWithMoment(deliveryDate) : null,
      id,
      pickupDate: pickupDate ? parseDateWithMoment(pickupDate) : null,
      status,
      updatedAt
    }
  });

  const updateCartDetailOnSave = ({
    pickupDate: updatedPickupDate,
    deliveryDate: updatedDeliveryDate,
    ...otherData
  }) => {
    const pickupDateInputForFormating = {
      date: updatedPickupDate,
      format: 'YYYY-MM-DD'
    };
    const deliveryDateInputForFormating = {
      date: updatedDeliveryDate,
      format: 'YYYY-MM-DD'
    };
    updateCart({
      setLoading,
      setShowToast,
      setUserEventCartDetails,
      updateCartDetail: {
        data: {
          pickupDate: updatedPickupDate
            ? getFormattedDate(pickupDateInputForFormating)
            : null,
          deliveryDate: updatedDeliveryDate
            ? getFormattedDate(deliveryDateInputForFormating)
            : null,
          ...otherData
        }
      },
      userId,
      userEventId
    });
    setEditCartSummaryModal({ showModal: false, cartSummary: null });
  };

  const statusOptions = [
    {
      disabled: status === CART_STATUS.COLLABORATE.value,
      label: CART_STATUS.DRAFT.value,
      value: CART_STATUS.DRAFT.value
    },
    {
      label: CART_STATUS.COLLABORATE.value,
      value: CART_STATUS.COLLABORATE.value
    }
  ];

  const actionButtonPairList = [
    {
      buttonName: 'Cancel',
      className: 'bg-gray text-black',
      onClick: () =>
        setEditCartSummaryModal({ showModal: false, cartSummary: null })
    },
    {
      buttonName: 'Save',
      className: 'bg-brand-gradient text-white',
      onClick: handleSubmit((data) =>
        onSubmitCartChanges({
          allocationStatus,
          setCartStatusChangeValidationError,
          status,
          updateCartDetailOnSave,
          userEventInformation,
          ...data
        })
      )
    }
  ];

  const datePickerFields = [
    {
      dbName: 'deliveryDate',
      label: 'Delivery Date',
      minDate: twoDaysBeforeDateOfEvent,
      selectDate: getValues('deliveryDate'),
      selectedDate,
      value: deliveryDate
    },
    {
      dbName: 'pickupDate',
      label: 'Pickup Date',
      minDate: dateOfEvent,
      selectDate: getValues('pickupDate'),
      selectedDate,
      value: pickupDate
    }
  ];

  return (
    <div className='flex gap-5 justify-center  h-screen mx-auto text-sm font-medium'>
      <div className='self-center w-41 z-20 bg-white rounded-lg px-8 py-6 '>
        <CloseIcon
          {...{
            onClick: () =>
              setEditCartSummaryModal({ showModal: false, cartSummary: null })
          }}
        />
        <Text
          className='text-lg font-medium text-center'
          content='Cart Details'
        />
        <LabelValuePair
          {...{
            className: 'flex gap-2 flex-col border-b border-platinum pb-2 my-5',
            label: 'Cart Id',
            value: id
          }}
        />
        <LabelInputPair
          {...{
            className: 'border-none padding-left-none',
            content: 'Cart Name',
            dbName: 'cartName',
            defaultValue: cartName,
            errors,
            register
          }}
        />
        <div className='flex gap-10 justify-between'>
          <LabelValuePair
            {...{
              label: 'Cart Number',
              value: cartNumber
            }}
          />
          <div className='flex w-1/2 gap-2 flex-col pb-2 my-5'>
            <Text
              className='text-nero'
              content='Status'
            />
            <Dropdown
              {...{
                clearErrors,
                control,
                dbName: 'status',
                errors,
                getValues,
                name: 'Status',
                onChange: (e) => {
                  setValue('status', e.value);
                  clearErrors();
                },
                options: statusOptions,
                placeholder: 'Select status',
                register,
                setValue
              }}
            />
          </div>
        </div>
        <div className='flex gap-10 justify-between'>
          <LabelValuePair
            {...{
              label: 'Created At',
              value: convertToShortMonthDateFormat(createdAt)
            }}
          />
          <LabelValuePair
            {...{
              label: 'Updated At',
              value: convertToShortMonthDateFormat(updatedAt)
            }}
          />
        </div>
        <div className='flex gap-10 justify-between'>
          {datePickerFields.map(
            ({ label, value, dbName, selectDate, minDate }, index) => (
              <DatePickerField
                key={index}
                {...{
                  dbName,
                  errors,
                  label,
                  minDate,
                  selectedDate: selectDate,
                  setSelectedDate,
                  setValue,
                  value
                }}
              />
            )
          )}
        </div>
        {cartStatusChangeValidationError && (
          <CartStatusChangeValidationErrorMessage
            {...{
              userEventId,
              setEditCartSummaryModal
            }}
          />
        )}
        <ActionButtonPair {...{ actionButtonPairList }} />
      </div>
    </div>
  );
};

const EditableCartSummaryModal = ({
  editCartSummaryModal: { showModal, cartSummary },
  setEditCartSummaryModal,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventInformation
}) => {
  const {
    id: userEventId,
    userEventDetails: [{ eventDate = '', opsUser } = {}] = [],
    userId,
    zendeskTicketId
  } = userEventInformation;

  const isZendeskIdAgentNameAvailable = Boolean(
    opsUser?.name && zendeskTicketId
  );

  const closeEditCartSummaryModal = () =>
    setEditCartSummaryModal({ showModal: false, cartSummary: null });

  return (
    <Modal
      backdrop={true}
      centered
      fade={false}
      isOpen={showModal}
      size='lg'
      toggle={closeEditCartSummaryModal}
    >
      <ModalBody>
        <CartSummaryModal
          {...{
            cartSummary,
            eventDate,
            isZendeskIdAgentNameAvailable,
            setEditCartSummaryModal,
            setLoading,
            setShowToast,
            setUserEventCartDetails,
            userEventId,
            userEventInformation,
            userId
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default EditableCartSummaryModal;
