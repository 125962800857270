export default function LdJSONSchemaScriptTag({ ldJson }) {
  const attributes = {
    type: 'application/ld+json',
    dangerouslySetInnerHTML: { __html: JSON.stringify(ldJson) }
  };
  if (ldJson?.id) {
    attributes.key = ldJson.id;
  }
  return <script {...attributes} />;
}
