import Image from 'next/image';
import { useState } from 'react';

import { CartOrderImageGalleryModal } from '@/components/atomic/atoms';
import { ImageCarouselModal } from '@/components/atomic/nuclei';
import {
  LINE_ITEM_SOURCE,
  NO_PRODUCT_IMAGE,
  staticMediaStoreBaseURL
} from '@/config/common';
import { getImageURL } from '@/helpers/carousel';
import { useIsMobile } from '@/lib/screenResolution';
import { updateShowProductMediaToggle } from '@/services/cart.plan.service';
import { noProductImageURL } from '@/services/plannerPortal.service';

const CoverImage = ({
  coverImage,
  isPlannerCartAndProductMediaIsHidden,
  setOpenImageGalleryModal
}) => {
  const [isMobile] = useIsMobile();
  const [imageURL, setImageURL] = useState(getImageURL(coverImage));

  return (
    <div
      className='cursor-pointer'
      onClick={() => {
        setOpenImageGalleryModal(true);
      }}
    >
      <div className='absolute top-2 left-1 z-10'>
        <Image
          {...{
            alt: 'view all images',
            className: 'cursor-pointer',
            height: 0,
            priority: true,
            src: `${staticMediaStoreBaseURL}/icons/resize-icon.svg`,
            style: { hight: 20, width: 20 },
            width: 0
          }}
        />
      </div>
      <div
        className={`w-full md:w-40 product-image border-1 relative ${
          isPlannerCartAndProductMediaIsHidden
            ? 'border-2 border-orange rounded-md'
            : ''
        }`}
      >
        <Image
          {...{
            alt: 'product-image',
            className: 'rounded-lg thumb-image',
            height: 0,
            onError: () => setImageURL(noProductImageURL),
            priority: true,
            src: imageURL,
            style: { height: isMobile ? 59 : 100, width: isMobile ? 94 : 160 },
            unoptimized: true,
            width: 0
          }}
        />
      </div>
    </div>
  );
};

const updateImageTitle = (image) => ({
  ...image,
  imageTitle:
    image.entityType === 'product' ? 'Existing Media' : 'Additional Media'
});

const LineItemMedia = ({
  isCartEditable = true,
  isReadOnly = false,
  itemId,
  itemMediaForCarousel: itemMediaForCarouselRaw = [],
  itemParentId,
  onDeleteCartItemMedia,
  productName,
  productSource = LINE_ITEM_SOURCE.CART.value,
  setCartItemIdForUploadMediaModal,
  setOrderItemIdForUploadMediaModal,
  setShowToast,
  showProductMedia,
  unAvailableCartItem = false,
  updateCartItemMediaSortOrder,
  userId
}) => {
  const [openImageGalleryModal, setOpenImageGalleryModal] = useState(false);
  const [carouselModalImage, setCarouselModalImage] = useState(null);
  const [isShowProductMedia, setIsShowProductMedia] = useState(
    Boolean(showProductMedia)
  );
  const itemMediaForCarousel = itemMediaForCarouselRaw.map((image) =>
    updateImageTitle(image)
  );

  if (itemMediaForCarousel.length === 0) {
    itemMediaForCarousel.push(NO_PRODUCT_IMAGE);
  }

  const additionalLineItemMedia = itemMediaForCarousel.filter(
    ({ entityType }) => ['cartItem', 'orderItem'].includes(entityType)
  );

  const isPlannerCartAndProductMediaIsHidden = !showProductMedia;

  const onClickToggleButton = async ({ showProductMediaFlag }) => {
    updateShowProductMediaToggle({
      cartId: itemParentId,
      cartItemId: itemId,
      setIsShowProductMedia,
      setShowToast,
      showProductMediaFlag,
      userId
    });
  };

  return (
    <div className='relative ss'>
      <CoverImage
        {...{
          coverImage: itemMediaForCarousel[0],
          isPlannerCartAndProductMediaIsHidden,
          setOpenImageGalleryModal
        }}
      />
      <CartOrderImageGalleryModal
        {...{
          additionalLineItemMedia,
          isCartEditable,
          isReadOnly,
          isShowProductMedia,
          itemId,
          itemMediaForCarousel,
          itemParentId,
          onClickToggleButton,
          onDeleteCartItemMedia,
          openImageGalleryModal,
          productName,
          productSource,
          setCarouselModalImage,
          setCartItemIdForUploadMediaModal,
          setOpenImageGalleryModal,
          setOrderItemIdForUploadMediaModal,
          setShowToast,
          unAvailableCartItem,
          updateCartItemMediaSortOrder
        }}
      />
      {carouselModalImage && (
        <ImageCarouselModal
          {...{
            images: itemMediaForCarousel,
            modalImage: carouselModalImage,
            openImageModal: setCarouselModalImage
          }}
        />
      )}
    </div>
  );
};

export default LineItemMedia;
