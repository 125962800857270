import Image from 'next/image';
import Link from 'next/link';
import { Fragment, useState } from 'react';

import { BidItemResponse } from '@/components/atomic/atoms';
import { ShowMoreLessCTA, Text } from '@/components/atomic/nuclei';
import {
  BID_SUBMISSION_STATUS,
  staticMediaStoreBaseURL
} from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import {
  bidSubmissionStatusMap,
  getEditPartnerURL,
  tenderResponseByPartnerHeaderConfig
} from '@/services/tenderResponse.service';

const COMMENT_LENGTH = 100;

const TenderResponseByPartnerHeader = () => (
  <div className='flex justify-between bg-black text-white py-5 text-xs font-semibold uppercase rounded-t-2.5 px-4'>
    {tenderResponseByPartnerHeaderConfig.map(({ className, content }, id) => (
      <Text
        key={id}
        {...{ className, content }}
      />
    ))}
  </div>
);

const BidResponse = ({
  bid,
  isEvenRow,
  isLastItem,
  rowDetailToView,
  setRowDetailToView
}) => {
  const {
    availableItemCount,
    deliveryCharge,
    id: bidId,
    offerAmount,
    partner,
    partnerId,
    partnerNotes,
    requestedItemCount,
    submissionStatus
  } = bid;
  const [showMore, setShowMore] = useState(
    partnerNotes?.length > COMMENT_LENGTH
  );
  const lastItemCollapsedView = isLastItem && !rowDetailToView?.bidId;
  return (
    <div
      onClick={() =>
        rowDetailToView?.bidId !== bidId
          ? setRowDetailToView({ bidId })
          : setRowDetailToView()
      }
      className={`flex justify-between ${
        isEvenRow ? 'bg-platinum' : 'bg-white'
      } ${
        lastItemCollapsedView ? 'rounded-b-2.5' : ''
      }  text-nero py-5 text-sm font-medium px-4 border-l border-r border-b cursor-pointer`}
    >
      <div className='flex self-center gap-3 w-60 '>
        <div className='w-6 h-6 flex self-center'>
          <Link
            href={getEditPartnerURL({ partnerId })}
            target='_blank'
          >
            <Image
              alt='hafla'
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/hafla-round-icon.svg`}
              style={{ hight: 24, width: 24 }}
              width={0}
            />
          </Link>
        </div>
        <Text
          {...{
            className: 'flex flex-1 self-center',
            content: partner.legalName || '-'
          }}
        />
      </div>
      <Text
        {...{
          className: 'w-24 flex  self-center',
          content: `${requestedItemCount} items` || '-'
        }}
      />
      <Text
        {...{
          className: 'w-24 flex  self-center',
          content: `${availableItemCount} items` || '-'
        }}
      />
      <Text
        {...{
          className: 'w-32 flex  self-center',
          content: deliveryCharge
            ? parseFormatPriceValueFromAPI(deliveryCharge)
            : '-'
        }}
      />
      <Text
        {...{
          className: 'w-28 flex  self-center',
          content: offerAmount ? parseFormatPriceValueFromAPI(offerAmount) : '-'
        }}
      />
      <ShowMoreLessCTA
        {...{
          className: 'w-1112',
          setShowMore,
          showMore,
          textValue: partnerNotes
        }}
      />

      <div className='w-40 flex  justify-between'>
        <Text
          {...{
            className: `flex self-center text-center text-xs font-medium ${bidSubmissionStatusMap[submissionStatus]} py-1 px-2 rounded-full`,
            content: BID_SUBMISSION_STATUS[submissionStatus].label || '-'
          }}
        />
        <div
          className='w-5 h-5 flex self-center'
          onClick={() =>
            rowDetailToView?.bidId !== bidId
              ? setRowDetailToView({ bidId })
              : setRowDetailToView()
          }
        >
          <Image
            alt='arrow'
            className={`transform ${
              rowDetailToView?.bidId === bidId ? 'rotate-90' : 'rotate-0'
            }`}
            height={0}
            src={`${staticMediaStoreBaseURL}/icons/next-arrow.svg`}
            style={{ height: 16, width: 16 }}
            width={0}
          />
        </div>
      </div>
    </div>
  );
};

const TenderResponseByPartnerRow = ({
  bid,
  isEvenRow,
  isLastItem,
  rowDetailToView,
  setRowDetailToView
}) => (
  <Fragment>
    <BidResponse
      {...{
        bid,
        isEvenRow,
        isLastItem,
        rowDetailToView,
        setRowDetailToView
      }}
    />
    {rowDetailToView?.bidId === bid.id && (
      <BidItemResponse {...{ bidItems: bid.bidItems, isEvenRow }} />
    )}
  </Fragment>
);

const TenderResponseByPartner = ({
  bids,
  rowDetailToView,
  setRowDetailToView
}) => {
  const rowCount = bids.length;
  return (
    <div>
      <TenderResponseByPartnerHeader />
      {bids.map((bid, index) => (
        <TenderResponseByPartnerRow
          key={index}
          {...{
            bid,
            isEvenRow: index % 2 === 0,
            isLastItem: index + 1 === rowCount,
            setRowDetailToView,
            rowDetailToView
          }}
        />
      ))}
    </div>
  );
};

export default TenderResponseByPartner;
