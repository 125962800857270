import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';

import { TenderItemResponse } from '@/components/atomic/atoms';
import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import {
  getEditProductURL,
  tenderResponseByItemHeaderConfig
} from '@/services/tenderResponse.service';

const TenderResponseByItemHeader = () => (
  <div className='flex justify-between bg-black text-white py-5 text-xs font-semibold uppercase rounded-t-2.5 px-4'>
    {tenderResponseByItemHeaderConfig.map(({ className, content }, id) => (
      <Text
        key={id}
        {...{ className, content }}
      />
    ))}
  </div>
);

const TenderResponseByItemSubHeader = ({
  bidResponsesOfItem,
  isEvenRow,
  isLastItem,
  rowDetailToView,
  setRowDetailToView
}) => {
  const {
    name,
    productBidInfoList,
    productId,
    quantityRequested,
    receivedFromPartners,
    unitPriceRequested
  } = bidResponsesOfItem;

  return (
    <Fragment>
      <div
        onClick={() =>
          rowDetailToView?.productId !== productId
            ? setRowDetailToView({ productId })
            : setRowDetailToView()
        }
        className={`flex justify-between ${
          isEvenRow ? 'bg-platinum' : 'bg-white'
        } ${
          isLastItem && !rowDetailToView?.productId ? 'rounded-b-2.5' : ''
        } text-nero py-5 text-sm font-medium px-4  border-l border-r border-b cursor-pointer`}
      >
        <div className='flex self-center gap-3 w-112'>
          <div className='w-6 h-6 flex self-center'>
            <Link
              href={getEditProductURL({ productId })}
              target='_blank'
            >
              <Image
                alt='hafla'
                height={0}
                src={`${staticMediaStoreBaseURL}/icons/hafla-round-icon.svg`}
                style={{ height: 24, width: 24 }}
                width={0}
              />
            </Link>
          </div>
          <Text
            {...{
              className: 'flex flex-1 self-center',
              content: name || '-'
            }}
          />
        </div>
        <Text
          {...{
            className: 'w-40 flex self-center',
            content: quantityRequested ? String(quantityRequested) : '-'
          }}
        />
        <Text
          {...{
            className: 'w-40 flex self-center',
            content: String(
              parseFormatPriceValueFromAPI(
                unitPriceRequested * quantityRequested
              )
            )
          }}
        />
        <Text
          {...{
            className: 'w-48 flex self-center',
            content: productBidInfoList.length
              ? String(productBidInfoList.length)
              : '-'
          }}
        />
        <div className='w-48 flex justify-between self-center'>
          <Text
            {...{
              className: 'flex self-center',
              content: String(receivedFromPartners)
            }}
          />
          <div
            onClick={() =>
              rowDetailToView?.productId !== productId
                ? setRowDetailToView({ productId })
                : setRowDetailToView()
            }
          >
            <Image
              alt='arrow'
              className={`transform ${
                rowDetailToView?.productId === productId
                  ? 'rotate-90'
                  : 'rotate-0'
              }`}
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/next-arrow.svg`}
              style={{ height: 16, width: 16 }}
              width={0}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const TenderResponseByItemRow = ({
  bidResponsesOfItem,
  isEvenRow,
  isLastItem,
  rowDetailToView,
  setRowDetailToView
}) => (
  <Fragment>
    <TenderResponseByItemSubHeader
      {...{
        bidResponsesOfItem,
        isEvenRow,
        isLastItem,
        rowDetailToView,
        setRowDetailToView
      }}
    />
    {rowDetailToView?.productId === bidResponsesOfItem.productId && (
      <TenderItemResponse
        {...{
          isEvenRow,
          productBidInfoList: bidResponsesOfItem.productBidInfoList
        }}
      />
    )}
  </Fragment>
);

const TenderResponseByItem = ({
  bidResponsesOfItems,
  rowDetailToView,
  setRowDetailToView
}) => {
  const rowCount = bidResponsesOfItems.length;
  return (
    <div>
      <TenderResponseByItemHeader />
      {bidResponsesOfItems.map((bidResponsesOfItem, index) => (
        <TenderResponseByItemRow
          key={index}
          {...{
            bidResponsesOfItem,
            isEvenRow: index % 2 === 0,
            isLastItem: index + 1 === rowCount,
            rowDetailToView,
            setRowDetailToView
          }}
        />
      ))}
    </div>
  );
};

export default TenderResponseByItem;
