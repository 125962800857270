import { useEffect, useState } from 'react';

import { isBrowser } from 'lib/utils';

const useScrollVisibility = ({
  initialVisibility = true,
  additionalOperations = () => {}
}) => {
  const [isVisible, setIsVisible] = useState(initialVisibility);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      setIsVisible(currentScrollPosition > lastScrollPosition);
      setLastScrollPosition(currentScrollPosition);
      additionalOperations();
    };

    if (isBrowser()) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollPosition]);
  return isVisible;
};

export default useScrollVisibility;
