import { Fragment } from 'react';

import { SectionHeading } from '@/components/atomic/atoms';
import { Radio, Text } from '@/components/atomic/nuclei';
import { modeOfConfirmations } from '@/services/cart.plan.service';

const ModeOfConfirmation = ({
  errors,
  getValues,
  handleModeOfCommunication,
  register
}) => (
  <Fragment>
    <SectionHeading
      {...{
        label: 'Mode of confirmation',
        message: `Mark Prepaid, If the customer has made any advance payment to.wards the order. Otherwise mark the appropriate channel where the customer agreed to pay`,
        top: '-top-6'
      }}
    />
    <div className='my-4'>
      <div className='flex gap-4'>
        {Object.values(modeOfConfirmations).map(({ label, value }, index) => (
          <div
            className={`w-42.5 bg-lightgray h-11 rounded p-3 text-black shadow-card ${
              getValues('modeOfConfirmation') === value
                ? 'border border-brand'
                : ''
            }`}
            key={index}
            onClick={() => handleModeOfCommunication(value)}
          >
            <Radio
              {...{
                dbName: 'modeOfConfirmation',
                direction: 'right',
                errors,
                label,
                labelClass: 'text-sm font-medium',
                name: 'modeOfConfirmation',
                radioClass: 'radio-w16',
                register,
                value
              }}
            />
          </div>
        ))}
      </div>
      <Text
        {...{
          className: 'text-xs text-red-500 mt-1',
          content: errors?.modeOfConfirmation?.message
        }}
      />
    </div>
  </Fragment>
);

export default ModeOfConfirmation;
