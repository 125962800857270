import { AddAddress } from '@/components/atomic/atoms';
import { BottomModal } from '@/components/atomic/nuclei';

const AddressModal = ({
  addAddressToggler,
  cityList,
  handleSubmit,
  setShowAddressError,
  setUserAddress,
  userAddress
}) => (
  <BottomModal
    paramData={{ showHeader: false, setCloseModal: addAddressToggler }}
  >
    <AddAddress
      {...{
        addAddressToggler,
        cityList,
        handleSubmit,
        setShowAddressError,
        setUserAddress,
        userAddress
      }}
    />
  </BottomModal>
);

export default AddressModal;
