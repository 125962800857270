import Image from 'next/image';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';

import { NavigationMenu } from '@/components/atomic/atoms';
import { Button } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import useScrollVisibility from '@/hooks/useScrollVisibility';
import { getPlannerName } from '@/services/identity.service';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const HeaderOrganism = ({
  headerContainerStyle = '',
  headerStyle = 'w-full'
}) => {
  const router = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [plannerName, setPlannerName] = useState(null);

  const isHeaderVisible = useScrollVisibility({
    additionalOperations: () => {
      setIsMenuOpen(false);
    },
    initialVisibility: false
  });

  const showNavigationOptions = Boolean(plannerName);

  useEffect(() => {
    setPlannerName(getPlannerName());
  }, []);

  return (
    <Fragment>
      <div
        className={`fixed flex bg-white h-[64px] z-30 px-10 page-header w-full ${isHeaderVisible ? 'top-[-65px]' : 'top-0'} ${headerContainerStyle}`}
      >
        <div
          className={`flex relative z-50 justify-between items-center self-center ${headerStyle}`}
        >
          <div className='flex gap-10'>
            <div className='self-center w-64'>
              <Image
                alt='hafla-logo'
                className='cursor-pointer'
                height={0}
                onClick={() =>
                  router.push(
                    getPageURL({
                      pageName: PAGE_NAME.WELCOME.label
                    })
                  )
                }
                priority={true}
                src={`${staticMediaStoreBaseURL}/icons/hafla.svg`}
                style={{ height: 32, width: 100 }}
                width={0}
              />
            </div>
          </div>
          <div className='flex gap-6 h-10'>
            {showNavigationOptions && (
              <Button
                {...{
                  className:
                    'text-white font-medium text-base bg-brand-gradient rounded-lg px-4',
                  iconHeight: 20,
                  iconPosition: 'Right',
                  iconURL: `${staticMediaStoreBaseURL}/icons/navigate-white-icon.svg`,
                  iconWidth: 20,
                  label: 'Quick Find',
                  onClick: () =>
                    router.push(
                      getPageURL({
                        pageName: PAGE_NAME.NAVIGATION.label
                      })
                    )
                }}
              />
            )}
            {showNavigationOptions && (
              <NavigationMenu {...{ isMenuOpen, plannerName, setIsMenuOpen }} />
            )}
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className='fixed w-full h-full top-0 left-0 black-overlayer z-20' />
      )}
    </Fragment>
  );
};

export default HeaderOrganism;
