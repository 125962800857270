import Image from 'next/image';
import { useState } from 'react';

import { EventOrderBanner, EventOrderDetails } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const EventOrderInfo = ({ orderBannerDetail, userEventDetails }) => {
  const [showEventOrderDetails, setShowEventOrderDetails] = useState(true);
  return (
    <>
      <div
        className='mb-6 mt-16 relative'
        onClick={() => setShowEventOrderDetails(!showEventOrderDetails)}
      >
        <EventOrderBanner {...{ orderBannerDetail }} />
        <div className='absolute -bottom-5 right-4'>
          <Image
            alt='down angle'
            className={`cursor-pointer transform ${
              showEventOrderDetails ? 'rotate-180' : 'rotate-0'
            }`}
            height={0}
            priority={true}
            src={`${staticMediaStoreBaseURL}/icons/down-angle-circle-icon.svg`}
            style={{
              height: 40,
              width: 40
            }}
            width={0}
          />
        </div>

        {showEventOrderDetails && (
          <EventOrderDetails {...{ userEventDetails }} />
        )}
      </div>
    </>
  );
};

export default EventOrderInfo;
