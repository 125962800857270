import Image from 'next/image';
import { Fragment } from 'react';

import { SectionHeading } from '@/components/atomic/atoms';
import { Radio, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { paymentModes } from '@/services/cart.plan.service';

const ModeOfPayment = ({
  errors,
  getValues,
  handleModeOfPayment,
  register
}) => (
  <Fragment>
    <SectionHeading
      {...{
        className: 'mt-1',
        label: 'Mode of payment',
        message: 'Mandatory if Mode of confirmation is Prepaid.'
      }}
    />
    <div className='my-4'>
      <div className='flex gap-4'>
        {Object.values(paymentModes).map(
          ({ alt, icon, label, value }, index) => (
            <div
              key={`${value}_${index}`}
              className={`w-40 bg-lightgray text-black p-3 h-18 rounded shadow-card flex flex-col gap-2 ${
                getValues('paymentProviderType') === value
                  ? 'border border-brand'
                  : ''
              }`}
              onClick={() => handleModeOfPayment(value)}
            >
              <div className='flex justify-between'>
                <Image
                  alt={alt}
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                  style={{ height: 28, width: 28 }}
                  width={0}
                />
                <Radio
                  {...{
                    dbName: 'paymentProviderType',
                    direction: 'right',
                    errors,
                    labelClass: 'text-sm font-medium',
                    name: 'paymentProviderType',
                    radioClass: 'radio-w16',
                    register,
                    value
                  }}
                />
              </div>
              <Text
                {...{
                  className: 'text-sm font-medium',
                  content: label
                }}
              />
            </div>
          )
        )}
      </div>
      <Text
        {...{
          className: 'text-xs text-red-500 mt-1',
          content: errors?.paymentProviderType?.message
        }}
      />
    </div>
  </Fragment>
);

export default ModeOfPayment;
