import { Toaster } from '@/components/atomic/atoms';

const ToastContainer = ({ setShowToast, showToast }) => {
  const { message, show, status } = showToast;
  return (
    show && (
      <div className='flex mx-auto gap-4'>
        <Toaster
          {...{
            iconSrc: status ? 'checked-green.svg' : 'failed.svg',
            message,
            setMessage: setShowToast,
            showIcon: true
          }}
        />
      </div>
    )
  );
};

export default ToastContainer;
