import { SectionHeading } from '@/components/atomic/atoms';
import { InputField } from '@/components/atomic/nuclei';
import { otherFields } from '@/services/cart.plan.service';

const PaymentRemarkRow = ({ errors, register }) => (
  <div className='flex gap-4 my-5'>
    {otherFields.map(
      ({ infoMessage, inputType, placeholder, dbName, label }, key) => (
        <div
          className={`flex flex-col ${
            dbName === 'reference' ? 'w-1/3' : 'w-2/3'
          }`}
          key={key}
        >
          <SectionHeading
            {...{
              errors,
              label,
              message: infoMessage,
              top: dbName === 'paymentRemarks' ? '-top-8' : '-top-3',
              width: dbName === 'paymentRemarks' ? 'w-56' : 'w-100'
            }}
          />
          <InputField
            {...{
              className: 'rounded-lg text-base',
              dbName,
              errors,
              inputGroup: 'w-full mt-3',
              placeholder,
              register: register(dbName),
              type: inputType
            }}
          />
        </div>
      )
    )}
  </div>
);

export default PaymentRemarkRow;
