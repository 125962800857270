import Image from 'next/image';
import Link from 'next/link';

import { RackRateOfOrderedCartItem } from '@/components/atomic/atoms';
import { CartItemDetail, LineItemMedia } from '@/components/atomic/molecules';
import { Text } from '@/components/atomic/nuclei';
import { LINE_ITEM_SOURCE, staticMediaStoreBaseURL } from '@/config/common';
import {
  getCartItemFields,
  getProductPartnerListObject
} from '@/services/cart.plan.service';
import { getProductDetailPageURL } from '@/services/hostPortal.service';

const OrderedCartItemList = ({
  cartItem,
  cartItemIdInViewEditMode,
  isCartEditable,
  setCartItemIdInViewEditMode
}) => {
  const {
    cartItemDerivedValue,
    id,
    itemMediaForCarousel,
    partner,
    product,
    quantity,
    showProductMedia
  } = cartItem;

  const {
    haflaMarginPercent,
    rackDiscountPercentage,
    sellingPrice,
    unitListedDiscount,
    unitListedPrice,
    unitRackPrice,
    unitSellingPrice
  } = cartItemDerivedValue;
  const { name, type, slug } = product;

  const cartItemFields = getCartItemFields({
    haflaMarginPercent,
    partnerLegalName: partner?.legalName || '',
    quantity,
    sellingPrice,
    unitSellingPrice
  });

  return (
    <div className='w-full'>
      <div className='flex gap-5 text-sm font-light py-3 border-b border-platinum bg-lightgray px-4 rounded-t-lg'>
        <div className='flex-1 flex gap-3 self-center'>
          <LineItemMedia
            {...{
              isCartEditable,
              isReadOnly: true,
              itemId: id,
              itemMediaForCarousel,
              productName: name,
              productSource: LINE_ITEM_SOURCE.ORDER.value,
              showProductMedia
            }}
          />
          <Link
            href={getProductDetailPageURL({ slug })}
            className='self-center'
          >
            <Text
              {...{
                className: 'self-center flex-1 flex',
                content: name
              }}
            />
          </Link>
        </div>
        <div className='w-40 self-center'>
          <RackRateOfOrderedCartItem
            {...{
              rackDiscountPercentage,
              type,
              unitListedDiscount,
              unitListedPrice,
              unitRackPrice
            }}
          />
        </div>
        {cartItemFields.map(({ content, className }, index) => (
          <Text
            key={index}
            {...{ content, className }}
          />
        ))}

        <div
          className={`w-40 self-center flex justify-center transform rotate-${
            cartItemIdInViewEditMode === id ? '180' : '0'
          }`}
        >
          <Image
            alt='down'
            height={0}
            onClick={() => setCartItemIdInViewEditMode(id)}
            src={`${staticMediaStoreBaseURL}/icons/down-orange.svg`}
            style={{ height: 20, width: 20 }}
            width={0}
          />
        </div>
      </div>
    </div>
  );
};

const OrderedCartItemOrganism = ({
  itemListComponentCommonProps,
  itemList = []
}) => {
  const {
    cartItemViewEditMode,
    cartItemIdInViewEditMode,
    isCartEditable,
    setCartItemIdInViewEditMode,
    setCartItemViewEditMode,
    unSavedChangesErrorMessage,
    updateCartItemDetail
  } = itemListComponentCommonProps;

  return itemList.map((item) => (
    <div key={item.id}>
      <OrderedCartItemList
        {...{
          cartItem: item,
          cartItemIdInViewEditMode,
          setCartItemIdInViewEditMode
        }}
      />

      {cartItemIdInViewEditMode === item.id && (
        <CartItemDetail
          {...{
            cartItem: { ...item, derivedValues: item.cartItemDerivedValue },
            cartItemViewEditMode,
            isCartEditable,
            isReadOnly: true,
            productPartnersDropdownList: getProductPartnerListObject({
              partnerId: item.partner.id,
              legalName: item.partner.legalName || ''
            }),
            setCartItemIdInViewEditMode,
            setCartItemViewEditMode,
            unSavedChangesErrorMessage,
            updateCartItemDetail
          }}
        />
      )}
    </div>
  ));
};

export default OrderedCartItemOrganism;
