import _ from 'lodash';

export const PLANNER_PRODUCT_INDEX =
  process.env.NEXT_PUBLIC_PLANNER_PRODUCT_INDEX;

export const DEFAULT_HITS_PER_PAGE = 24;

export const DEFAULT_FILTER_OPTIONS_LIMIT = 5;

export const MIN_CHAR_FOR_SEARCH_REQUEST = 0;

export const SORT_OPTIONS = [
  {
    label: 'Relevance',
    value: PLANNER_PRODUCT_INDEX
  },
  {
    label: 'Price: High to Low',
    value: `${PLANNER_PRODUCT_INDEX}_sortPrice_desc`
  },
  {
    label: 'Price: Low to High',
    value: `${PLANNER_PRODUCT_INDEX}_sortPrice_asc`
  },
  {
    label: 'Weightage: High to low',
    value: `${PLANNER_PRODUCT_INDEX}_weightage_desc`
  },
  {
    label: 'Top Sellers',
    value: `${PLANNER_PRODUCT_INDEX}_userEventCount_desc`
  }
];

export const SEARCHABLE_ATTRIBUTES = [
  {
    esDocKey: 'everywhere',
    key: 'EVERYWHERE',
    label: 'Everywhere',
    sortOrder: 0
  },
  { esDocKey: 'name', key: 'TITLE', label: 'Title', sortOrder: 1 },
  {
    esDocKey: 'shortDescription',
    key: 'SHORT_DESCRIPTION',
    label: 'Short Description',
    sortOrder: 2
  },
  {
    esDocKey: 'description',
    key: 'DESCRIPTION',
    label: 'Description',
    sortOrder: 3
  },
  {
    esDocKey: 'inclusions',
    key: 'INCLUSIONS',
    label: 'Inclusions',
    sortOrder: 4
  },
  {
    esDocKey: 'productNumber',
    key: 'INTERNAL_ID',
    label: 'Product Number',
    sortOrder: 5
  },
  { esDocKey: 'id', key: 'UUID', label: 'Product ID', sortOrder: 6 }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));
