import { useForm } from 'react-hook-form';

import {
  CartSectionHeading,
  UnSavedChangesErrorMessage,
  WIPCartSummaryNote
} from '@/components/atomic/atoms';
import { VIEW_EDIT_MODE } from '@/config/cart';

const CartNote = ({
  cartDetails,
  cartNoteViewEditMode,
  isCartEditable,
  setCartNoteViewEditMode,
  title,
  unSavedChangesErrorMessage,
  updateCartDetailAPI
}) => {
  const { externalNotes, id, internalNotes } = cartDetails;
  const { handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      externalNotes,
      internalNotes
    }
  });

  const onClickEdit = () => {
    setCartNoteViewEditMode(VIEW_EDIT_MODE.EDIT);
  };

  const onClickDiscardChanges = () => {
    reset({ ...{ internalNotes, externalNotes } });
    setCartNoteViewEditMode(VIEW_EDIT_MODE.VIEW);
  };

  const saveChanges = (data) => {
    updateCartDetailAPI({ ...data, id });
    setCartNoteViewEditMode(VIEW_EDIT_MODE.VIEW);
  };

  const summaryNote = [
    {
      dbName: 'externalNotes',
      noteType: 'EXTERNAL',
      value: externalNotes
    },
    {
      dbName: 'internalNotes',
      noteType: 'INTERNAL',
      value: internalNotes
    }
  ];

  return (
    <div className='flex flex-col gap-4 my-4'>
      <CartSectionHeading
        {...{
          isCartEditable,
          mode: cartNoteViewEditMode,
          onClickDiscardChanges,
          onClickEdit,
          onClickSaveChanges: handleSubmit(saveChanges),
          title
        }}
      />
      <UnSavedChangesErrorMessage {...{ unSavedChangesErrorMessage }} />
      <div className='flex flex-col gap-3'>
        {summaryNote.map(({ noteType, value, dbName }, index) => (
          <WIPCartSummaryNote
            key={`${index}-${noteType}`}
            {...{
              dbName,
              disabled: cartNoteViewEditMode === VIEW_EDIT_MODE.VIEW,
              noteType,
              setValue,
              value
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CartNote;
