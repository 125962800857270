import { CTA } from '@/components/atomic/nuclei';

const CopyToClipBoardButton = ({ onClick }) => (
  <div className={`w-full flex py-3`}>
    <CTA
      {...{
        buttonColor:
          'bg-orange py-3 px-6 text-white cursor-pointer text-sm rounded-md',
        label: 'Copy to Clipboard',
        onClick
      }}
    />
  </div>
);

export default CopyToClipBoardButton;
