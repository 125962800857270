import Image from 'next/image';
import { memo, useEffect, useRef, useState } from 'react';
import { useSortBy } from 'react-instantsearch';

import { staticMediaStoreBaseURL } from '@/config/common';

const SortDropDownItem = ({ currentRefinement, handleOnChange, options }) => (
  <ul className='flex flex-col'>
    {options.map(({ label, value }, id) => (
      <li
        key={id}
        className={`relative py-1 block searchkit-filter-menu-item cursor-pointer hover:bg-brand-gradient hover:!text-white text-dim-gray`}
        onChange={() => handleOnChange(value)}
      >
        <label className='cursor-pointer block px-4'>
          <input
            {...{
              checked: currentRefinement === value,
              className: 'w-[0.1px] h-[0.1px] absolute left-full',
              name: 'sortByOption',
              onChange: () => handleOnChange(value),
              type: 'radio',
              value
            }}
          />
          <span className='text-sm  font-medium self-center'>{label}</span>
        </label>
      </li>
    ))}
  </ul>
);

const SortDropDown = ({ currentRefinement, handleOnChange, options }) => (
  <div>
    <div
      className={`w-52 right-0 border shadow-sm border-platinum bg-white absolute z-10 mt-10 searchkit-mobile-modal-popup rounded-md`}
    >
      <SortDropDownItem {...{ currentRefinement, handleOnChange, options }} />
    </div>
  </div>
);

const SortResultsModal = memo(({ items }) => {
  const { currentRefinement, options, refine } = useSortBy({ items });

  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);

  const handleOnChange = (value) => {
    refine(value);
    setIsSortDropdownOpen(false);
  };

  const selectedLabel = options.find(
    ({ value }) => value === currentRefinement
  ).label;

  const sortAttributeModalRef = useRef(null);

  const handleClickOutsideSortAttributeModal = (event) => {
    if (
      sortAttributeModalRef.current &&
      !sortAttributeModalRef.current.contains(event.target)
    ) {
      setIsSortDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideSortAttributeModal);
    return () => {
      document.removeEventListener(
        'click',
        handleClickOutsideSortAttributeModal
      );
    };
  }, []);

  return (
    <div
      className='relative border-platinum self-start mt-1 flex'
      ref={sortAttributeModalRef}
    >
      <div
        className={`px-4 py-1 rounded-md flex gap-2 bg-white border border-brand text-brand hover:bg-brand-gradient hover:text-white cursor-pointer sort-by`}
        onClick={() => setIsSortDropdownOpen(!isSortDropdownOpen)}
      >
        <div className='self-center'>
          <Image
            {...{
              alt: 'sort-icon',
              className: 'sort-icon-red',
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/sort-red.svg`,
              style: { height: 20, width: 20 },
              width: 0
            }}
          />
          <Image
            {...{
              alt: 'sort-icon',
              className: 'sort-icon-white',
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/sort-white.svg`,
              style: { height: 20, width: 20 },
              width: 0
            }}
          />
        </div>
        <span>{selectedLabel}</span>

        <div className='self-center'>
          <Image
            {...{
              alt: 'open-sort-modal',
              className: `transform angle-icon-red ${isSortDropdownOpen ? 'rotate-180' : 'rotate-0'}`,
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/angle-red.svg`,
              style: { height: 12, width: 12 },
              width: 0
            }}
          />
          <Image
            {...{
              alt: 'open-sort-modal',
              className: `transform angle-icon-white ${isSortDropdownOpen ? 'rotate-180' : 'rotate-0'}`,
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/down-angle-white.svg`,
              style: { height: 12, width: 12 },
              width: 0
            }}
          />
        </div>
      </div>
      {isSortDropdownOpen && (
        <SortDropDown
          {...{
            currentRefinement,
            handleOnChange,
            options,
            setIsSortDropdownOpen
          }}
        />
      )}
    </div>
  );
});

SortResultsModal.displayName = 'SortResultsModal';

export default SortResultsModal;
