import {
  DeliveryInfoMobile,
  GenericKeyValueBox,
  PriceSummary,
  TermsAndConditions
} from '@/components/atomic/atoms';
import { transformDeliveryDetailForMobile } from '@/services/order.service';

const OrderPriceSummaryWithDetailMobile = ({
  paymentInfo,
  priceInfo,
  termsAndConditions
}) => {
  const {
    deliveryDate,
    deliveryTime,
    externalNotes,
    headerLabel,
    pickupDate,
    pickupTime
  } = transformDeliveryDetailForMobile(priceInfo);

  return (
    <div className='flex gap-4 md:hidden flex-col'>
      <PriceSummary
        {...{
          priceInfo
        }}
      />
      <DeliveryInfoMobile
        {...{
          deliveryDate,
          deliveryTime,
          externalNotes,
          headerLabel,
          pickupDate,
          pickupTime
        }}
      />
      {paymentInfo && (
        <GenericKeyValueBox
          {...{
            boxTitle: 'Payment Info',
            keyValuePairList: paymentInfo
          }}
        />
      )}
      <TermsAndConditions {...{ termsAndConditions }} />
    </div>
  );
};

export default OrderPriceSummaryWithDetailMobile;
