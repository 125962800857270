import { GenericKeyValueBox, PriceSummary } from '@/components/atomic/atoms';
import { HAFLA_BANK_DETAILS } from '@/config/common';

const OrderPriceSummaryWithDetailWeb = ({ paymentInfo, priceInfo }) => (
  <div className='md:w-150 hidden md:flex flex-col gap-4'>
    <PriceSummary
      {...{
        priceInfo
      }}
    />
    {paymentInfo && (
      <GenericKeyValueBox
        {...{
          boxTitle: 'Payment Info',
          keyValuePairList: paymentInfo
        }}
      />
    )}
    <GenericKeyValueBox
      {...{
        boxTitle: 'Company Bank Details',
        keyValuePairList: HAFLA_BANK_DETAILS
      }}
    />
  </div>
);

export default OrderPriceSummaryWithDetailWeb;
