import { CTA } from '@/components/atomic/nuclei';

const LoginAndPaymentCTA = ({
  disableLoginButton,
  disableProceedToPayment,
  isLoginAndPaymentCTAVisible = true,
  onClickLogin,
  onClickProceedToPayment
}) =>
  isLoginAndPaymentCTAVisible && (
    <div className='flex gap-2 fixed left-0 bottom-0 bg-white w-full px-2 py-5 z-10'>
      <CTA
        {...{
          buttonColor: 'bg-white border border-brand px-2 py-4 text-brand',
          className: `w-full py-3 text-sm ${disableLoginButton ? 'pointer-events-none' : 'pointer-events-auto'}`,
          disabled: disableLoginButton,
          label: 'Login To Collaborate',
          onClick: onClickLogin
        }}
      />
      <CTA
        {...{
          buttonColor: 'bg-brand-gradient px-2 py-4 text-white',
          className: `w-full py-3 text-sm ${
            disableProceedToPayment
              ? 'pointer-events-none'
              : 'pointer-events-auto'
          }`,
          disabled: disableProceedToPayment,
          label: 'Proceed To Payment',
          onClick: onClickProceedToPayment
        }}
      />
    </div>
  );

export default LoginAndPaymentCTA;
