import { Fragment, useState } from 'react';

import { ListCard, PreviewCard } from '@/components/atomic/atoms';
import { Text } from '@/components/atomic/nuclei';

const NavigationSearchResult = ({ searchResults }) => {
  const [selectedPreviewCard, setSelectedPreviewCard] = useState(
    searchResults[0].previewCard
  );
  return (
    <Fragment>
      <div className='flex gap-6 px-8'>
        <div className='flex flex-1 self-start flex-col gap-4'>
          {searchResults.map(({ listCard, previewCard }) => (
            <div
              onClick={() => {
                setSelectedPreviewCard(previewCard);
              }}
              key={listCard.entityId}
            >
              <ListCard
                {...{
                  listCard,
                  isSelectedResult:
                    listCard.entityId === selectedPreviewCard.entityId
                }}
              />
            </div>
          ))}
        </div>
        <PreviewCard
          key={selectedPreviewCard.entityId}
          {...{
            selectedPreviewCard
          }}
        />
      </div>
      <div className='flex w-2/3 py-10 cursor-pointer'>
        <Text
          {...{
            className: 'text-base font-medium text-brand mx-auto',
            content: 'Show more'
          }}
        />
      </div>
    </Fragment>
  );
};

export default NavigationSearchResult;
