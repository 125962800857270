import Image from 'next/image';
import { memo, useMemo, useRef } from 'react';
import { useSearchBox } from 'react-instantsearch';

import { ScopeSearchAttributeModal } from '@/components/atomic/atoms';
import { Button } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  DEFAULT_TAB_CATEGORY,
  TAB_SEARCHABLE_ATTRIBUTE_MAP
} from '@/config/searchkit/navigation';

const CustomSearchBox = ({
  isSearchStalled,
  onQueryChange,
  onResetClick,
  onSearchQuery,
  query,
  ...props
}) => {
  const inputRef = useRef(null);

  const showResetIcon = !(query.length === 0 || isSearchStalled);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      inputRef.current.blur();
      onSearchQuery();
    }
  };

  return (
    <div className='relative'>
      <span className={`absolute top-5 left-3`}>
        <Image
          {...{
            alt: 'search',
            src: `${staticMediaStoreBaseURL}/icons/search_new.svg`,
            height: 0,
            style: { width: 16, height: 16 },
            width: 0
          }}
        />
      </span>
      <input
        {...props}
        className={`h-[56px] w-[58.6rem] rounded-r-full rounded-l-none shadow-navigate-cart border-neutral text-base text-nero search-input-box focus:outline-none focus:border-none pl-10`}
        onChange={(e) => onQueryChange(e)}
        onKeyDown={handleKeyPress}
        placeholder='Start Typing...'
        ref={inputRef}
        value={query}
      />
      {showResetIcon && (
        <span className='ais-SearchBox-reset'>
          <Image
            {...{
              alt: 'clear search',
              height: 0,
              onClick: onResetClick,
              src: `${staticMediaStoreBaseURL}/icons/clear-searchbox-icon.svg`,
              style: { height: 12, width: 12 },
              width: 0
            }}
          />
        </span>
      )}
    </div>
  );
};

const NavigationSearchBar = memo(
  ({
    className = 'flex mx-auto relative -top-3',
    query,
    searchableAttribute,
    searchButtonStyle = '',
    selectedTabMenu,
    setQuery,
    setSearchableAttribute,
    setShowResults
  }) => {
    const activeTabMenuCategory = selectedTabMenu || DEFAULT_TAB_CATEGORY;
    const searchAttributeLabelMap = useMemo(
      () => TAB_SEARCHABLE_ATTRIBUTE_MAP[activeTabMenuCategory],
      [activeTabMenuCategory]
    );

    const { refine, status } = useSearchBox();
    const isSearchStalled = status === 'stalled';

    const disableSearchButton = query.length < 3;

    const handleSearchChange = (event) => {
      setQuery(event.target.value);
    };

    const onSearchQuery = () => {
      if (!disableSearchButton) {
        setTimeout(() => {
          refine(query);
          setShowResults(true);
        }, 1000);
      }
    };

    const onResetClick = () => {
      refine('');
      setQuery('');
      setShowResults(false);
    };

    const selectedAttributeLabel =
      Object.values(searchAttributeLabelMap).find(
        ({ esDocKey }) => esDocKey === searchableAttribute
      )?.label ||
      Object.values(TAB_SEARCHABLE_ATTRIBUTE_MAP[DEFAULT_TAB_CATEGORY])[0]
        .label;

    return (
      <div className='mt-3'>
        <div className={className}>
          <ScopeSearchAttributeModal
            {...{
              searchableAttribute,
              searchAttributeLabelMap,
              selectedAttributeLabel,
              setSearchableAttribute
            }}
          />
          <div className='flex'>
            <CustomSearchBox
              aria-label='Search'
              {...{
                isSearchStalled,
                onQueryChange: handleSearchChange,
                onResetClick,
                onSearchQuery,
                query
              }}
            />
            <div className={`absolute right-4 ${searchButtonStyle}`}>
              <Button
                {...{
                  className:
                    'bg-brand-gradient rounded-full text-white text-base font-medium self-center py-2 w-24 mt-2',
                  disabled: disableSearchButton,
                  label: 'Search',
                  onClick: onSearchQuery,
                  width: 'w-24'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

NavigationSearchBar.displayName = 'NavigationSearchBar';

export default NavigationSearchBar;
