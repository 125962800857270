import { useState } from 'react';

import { TenderCartUserEventInfo } from '@/components/atomic/atoms';
import {
  TenderResponseByItem,
  TenderResponseByPartner
} from '@/components/atomic/molecules';
import { Button } from '@/components/atomic/nuclei';

const selectedTabClass =
  'bg-brand-gradient text-white py-3 px-3 rounded-2.5 font-medium';
const unselectedTabClass = 'bg-platinum py-3 rounded-2.5 flex font-medium';

const TenderResponseSummaryTabs = ({
  isSummaryByPartnerViewSelected,
  setIsSummaryByPartnerViewSelected
}) => (
  <div className='flex bg-platinum rounded-2.5 w-126 text-lg'>
    <Button
      {...{
        className: isSummaryByPartnerViewSelected
          ? selectedTabClass
          : unselectedTabClass,
        label: 'Summary By Partners',
        onClick: () => setIsSummaryByPartnerViewSelected(true),
        width: 'w-80'
      }}
    />
    <Button
      {...{
        className: isSummaryByPartnerViewSelected
          ? unselectedTabClass
          : selectedTabClass,
        label: 'Summary By Products',
        onClick: () => setIsSummaryByPartnerViewSelected(false),
        width: 'w-80'
      }}
    />
  </div>
);

const TenderResponseOrganism = ({
  rowDetailToView,
  setRowDetailToView,
  tenderResponse
}) => {
  const { bidResponsesOfItems, cartEventInfo } = tenderResponse;
  const { cart, userEventDetail, zendeskTicketId } = cartEventInfo;
  const [isSummaryByPartnerViewSelected, setIsSummaryByPartnerViewSelected] =
    useState(true);

  return (
    <div className='min-h-[37.5rem] mt-16'>
      <div className='flex flex-col gap-4 px-10 py-4'>
        <TenderCartUserEventInfo
          {...{ cart, userEventDetail, zendeskTicketId }}
        />
        <TenderResponseSummaryTabs
          {...{
            isSummaryByPartnerViewSelected,
            setIsSummaryByPartnerViewSelected
          }}
        />
        {isSummaryByPartnerViewSelected ? (
          <TenderResponseByPartner
            {...{
              bids: tenderResponse.bids,
              rowDetailToView,
              setRowDetailToView
            }}
          />
        ) : (
          <TenderResponseByItem
            {...{
              bidResponsesOfItems,
              rowDetailToView,
              setRowDetailToView
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TenderResponseOrganism;
