import { SectionHeading } from '@/components/atomic/atoms';
import { InputField } from '@/components/atomic/nuclei';
import {
  amountFields,
  modeOfConfirmations
} from '@/services/cart.plan.service';

const PaymentInfoRow = ({ errors, handleAmountReceived, register, watch }) => (
  <div className='flex gap-4'>
    {amountFields.map(
      ({ dbName, disable, inputType, label, placeholder }, key) => (
        <div
          className='w-1/3'
          key={key}
        >
          <SectionHeading
            {...{
              label,
              message: dbName === 'amountReceived'
            }}
          />
          <InputField
            {...{
              className: 'rounded-lg text-base',
              dbName,
              disabled:
                disable ||
                (watch &&
                  watch('modeOfConfirmation') !==
                    modeOfConfirmations.PREPAID.value),
              errors,
              inputGroup: 'w-full mt-3',
              onChange: handleAmountReceived,
              placeholder,
              register: register(dbName),
              type: inputType
            }}
          />
        </div>
      )
    )}
  </div>
);

export default PaymentInfoRow;
