// TODO: FV/HP - change other lister with this common hook.
import { useEffect } from 'react';

import { isBrowser } from '@/lib/utils';

const useEventListener = (eventType, callback) => {
  useEffect(() => {
    if (isBrowser()) {
      callback();
      window.addEventListener(eventType, callback);
      return () => {
        window.removeEventListener(eventType, callback);
      };
    }
    return () => {};
  }, [eventType, callback]);
};

export default useEventListener;
