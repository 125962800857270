import { ViewEditCTAPair } from '@/components/atomic/atoms';
import { Button, SectionHeader } from '@/components/atomic/nuclei';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const EventCartDetailHeader = ({
  backward,
  eventId,
  forward,
  heading,
  headingStyle,
  type,
  userId
}) => (
  <div className='flex justify-between my-6'>
    <div className='self-center flex gap-5'>
      <SectionHeader
        {...{
          heading,
          headingStyle
        }}
      />
    </div>
    <div className='flex gap-4'>
      <Button
        {...{
          className:
            'py-3 px-4 text-sm font-medium self-end rounded-lg border border-brand text-brand bg-white',
          label: 'View Planner Board',
          onClick: () => {
            window.open(
              getPageURL({
                excludeBaseUrl: false,
                pageName: PAGE_NAME.METABASE_PLANNER_BOARD.label,
                queryParams: {
                  event_id: eventId,
                  user_id: userId
                }
              }),
              '_blank'
            );
          },
          width: 'w-auto'
        }}
      />
      <ViewEditCTAPair
        {...{
          backward,
          containerClass: '',
          forward,
          type
        }}
      />
    </div>
  </div>
);

export default EventCartDetailHeader;
