import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';

import {
  ModeOfConfirmation,
  ModeOfPayment,
  PaymentInfoRow,
  PaymentRemarkRow
} from '@/components/atomic/molecules';
import {
  Button,
  CloseIcon,
  Text,
  TextWithIcon
} from '@/components/atomic/nuclei';
import { captureGTMEventProceedToPayment } from '@/lib/gtm';
import {
  formatToTwoDecimalString,
  parseFormatPriceValueFromAPI,
  parsePriceValueForAPI
} from '@/lib/numberStringUtils';
import { parseNumberedLabel } from '@/lib/utils';
import useYupValidator from '@/lib/yupValidator';
import {
  modeOfConfirmations,
  submitBookOrder
} from '@/services/cart.plan.service';

const BookOrderPaymentModalHeader = ({ modalHeaderTextWithIconList }) => (
  <div className='flex justify-between pt-3 pb-5 text-base font-medium'>
    {modalHeaderTextWithIconList.map(({ icon, label }) => (
      <TextWithIcon
        key={label}
        {...{
          className: 'py-1 px-2 border border-neutral rounded',
          icon,
          iconHeight: 20,
          iconWidth: 20,
          label: parseNumberedLabel(label),
          labelStyle: 'text-sm text-dim-gray'
        }}
      />
    ))}
  </div>
);

const BookOrderModalOrganism = ({
  cartDetails,
  setShowBookOrderModal,
  setShowToast,
  showBookOrderModal
}) => {
  const {
    cartItems,
    cartNumber,
    derivedValues: {
      orderTotal: orderTotalValue,
      promoCode: { code } = {}
    } = {},
    id: cartId,
    userCart: { hostNumber },
    userCartId: userId
  } = cartDetails;

  const router = useRouter();

  const yupResolver = useYupValidator(
    yup.object().shape({
      modeOfConfirmation: yup
        .string()
        .trim()
        .typeError('Mode Of Confirmation is mandatory!')
        .required('Mode Of Confirmation is mandatory!'),
      paymentProviderType: yup
        .string()
        .trim()
        .when('modeOfConfirmation', {
          is: modeOfConfirmations.PREPAID.value,
          then: () =>
            yup
              .string()
              .trim()
              .typeError(
                'Mode of payment is mandatory when prepaid confirmation!'
              )
              .required(
                'Mode of payment is mandatory when prepaid confirmation!'
              )
        })
        .nullable(),
      amountReceived: yup.number().when('modeOfConfirmation', {
        is: modeOfConfirmations.PREPAID.value,
        then: () =>
          yup.number().positive('Amount received should be > 0 when prepaid')
      }),
      paymentProviderId: yup
        .string()
        .trim()
        .required('Reference # is mandatory!'),
      paymentRemarks: yup
        .string()
        .trim()
        .required('Payment remarks is mandatory!')
    })
  );

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver,
    defaultValues: {
      amountDue: parseFormatPriceValueFromAPI(orderTotalValue) || '0.00',
      amountReceived: '0.00',
      orderTotal: parseFormatPriceValueFromAPI(orderTotalValue) || '0.00'
    }
  });

  const getAmountDue = (amountReceived) =>
    formatToTwoDecimalString({
      value: getValues('orderTotal') - amountReceived,
      convertValueToUpperDenomination: false,
      trimZeroFraction: false
    });

  const handleAmountReceived = (data) => {
    setValue('amountDue', getAmountDue(data.target.value || 0));
  };

  const handleModeOfCommunication = (value) => {
    setValue('modeOfConfirmation', value);
    setValue('amountReceived', 0);
    setValue('amountDue', getValues('orderTotal') || 0);
  };

  const handleModeOfPayment = (value) => {
    setValue('paymentProviderType', value, { shouldValidate: true });
  };

  const onSubmit = async ({
    amountDue: paymentDue,
    amountReceived: transactionAmount,
    modeOfConfirmation,
    orderTotal,
    paymentProviderId,
    paymentProviderType,
    paymentRemarks
  }) => {
    await submitBookOrder({
      bookOrderPaymentDetails: {
        modeOfConfirmation,
        orderTotal: parsePriceValueForAPI(orderTotal),
        paymentDue: parsePriceValueForAPI(paymentDue),
        paymentProviderId,
        paymentProviderType,
        paymentRemarks,
        transactionAmount: parsePriceValueForAPI(transactionAmount)
      },
      cartId,
      router,
      setShowToast,
      userId
    });
    captureGTMEventProceedToPayment({
      cartItems,
      code,
      orderTotal,
      paymentType: paymentProviderType
    });
  };

  const toggleBookOrderModalView = () =>
    setShowBookOrderModal(!showBookOrderModal);

  const modalHeaderTextWithIconList = [
    {
      icon: 'order-red-icon.svg',
      label: cartNumber
    },
    {
      icon: 'number-icon.svg',
      label: hostNumber
    }
  ];

  return (
    <Modal
      isOpen={true}
      toggle={toggleBookOrderModalView}
    >
      <ModalBody
        className='z-120 bg-white m-18 w-full md:w-56.25
      min-h-96 mx-auto px-8 md:rounded-lg pb-2 pt-4'
      >
        <div className='flex-1 flex flex-row-reverse relative left-4'>
          <CloseIcon {...{ onClick: toggleBookOrderModalView }} />
        </div>
        <Text
          {...{
            className: 'text-center text-xl font-medium pb-5',
            content: 'Book Order - Payment Details'
          }}
        />
        <BookOrderPaymentModalHeader
          {...{
            modalHeaderTextWithIconList
          }}
        />
        <div className='flex flex-col text-xl'>
          <ModeOfConfirmation
            {...{
              errors,
              getValues,
              handleModeOfCommunication,
              register
            }}
          />
          <ModeOfPayment
            {...{
              errors,
              getValues,
              handleModeOfPayment,
              register
            }}
          />
          <PaymentInfoRow
            {...{
              errors,
              handleAmountReceived,
              register,
              watch
            }}
          />
          <PaymentRemarkRow {...{ errors, register }} />
        </div>
        <Button
          {...{
            className: `py-3 px-2 text-sm font-medium mx-auto my-4 rounded-lg bg-brand-gradient text-white`,
            label: 'Confirm Order',
            onClick: handleSubmit(onSubmit),
            width: 'w-40'
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default BookOrderModalOrganism;
