import { useForm } from 'react-hook-form';

import {
  CartSectionHeading,
  PriceSummaryFooter,
  PriceSummaryHeader,
  PriceSummaryRow,
  UnSavedChangesErrorMessage
} from '@/components/atomic/atoms';
import { PRICE_SUMMARY_HEADERS, VIEW_EDIT_MODE } from '@/config/cart';
import { CART_STATUS } from '@/config/common';
import {
  calculateMarginPercent,
  decorateNumberForDisplay,
  parseFormatPriceValueFromAPI,
  parsePriceValueForAPI
} from '@/lib/numberStringUtils';
import {
  getAdditionalChargeAmount,
  getDeliveryChargeAmount,
  getPartnerTaxDetailsForNonOrderedCart,
  getPartnerTaxDetailsForOrderedCart
} from '@/services/cart.plan.service';

const getFormattedMarginPercent = (x, y) => {
  const percent = calculateMarginPercent(x, y);
  return Number.isNaN(percent) ? '-' : decorateNumberForDisplay(percent);
};

const CartPriceSummary = ({
  cartDetails,
  isCartEditable,
  priceSummaryViewEditMode,
  setPriceSummaryViewEditMode,
  unSavedChangesErrorMessage,
  updateCartDetailAPI
}) => {
  const {
    cartItems,
    derivedValues: {
      additionalChargeAmount,
      deliveryChargeAmount,
      listedDiscountAmount,
      listedPriceAmount,
      orderTotal,
      preVatAmount,
      promoCodeDiscountAmount,
      sellingPriceAmount,
      vatAmount
    } = {},
    id,
    lumpSumDiscount,
    nonTaxableCharge,
    status
  } = cartDetails;

  const { register, getValues, handleSubmit } = useForm({
    defaultValues: {
      additionalChargeAmount:
        parseFormatPriceValueFromAPI(additionalChargeAmount) || '0.00',
      deliveryChargeAmount:
        parseFormatPriceValueFromAPI(deliveryChargeAmount) || '0.00',
      lumpSumDiscount: parseFormatPriceValueFromAPI(lumpSumDiscount) || '0.00',
      nonTaxableCharge: parseFormatPriceValueFromAPI(nonTaxableCharge) || '0.00'
    }
  });

  const listedDeliveryChargeAmount = getDeliveryChargeAmount(cartItems);
  const listedAdditionalChargeAmount = getAdditionalChargeAmount(cartItems);

  const parsedDeliveryChargeAmount = parseFormatPriceValueFromAPI(
    listedDeliveryChargeAmount
  );

  const parsedAdditionalChargeAmount = parseFormatPriceValueFromAPI(
    listedAdditionalChargeAmount
  );

  const getPartnerTaxDetails =
    status === CART_STATUS.ORDER.value
      ? getPartnerTaxDetailsForOrderedCart
      : getPartnerTaxDetailsForNonOrderedCart;

  const { partnerPreVATAmount, partnerVATAmount } =
    getPartnerTaxDetails(cartItems);

  const partnerAmountWithVAT =
    Number(partnerVATAmount) + Number(partnerPreVATAmount);

  const summaryItems = [
    {
      absoluteMargin: parseFormatPriceValueFromAPI(
        listedPriceAmount - partnerPreVATAmount
      ),
      haflaPrice: parseFormatPriceValueFromAPI(listedPriceAmount),
      margin: getFormattedMarginPercent(partnerPreVATAmount, listedPriceAmount),
      partnerPrice: parseFormatPriceValueFromAPI(partnerPreVATAmount),
      rowTitle: 'Sub Total'
    },
    {
      absoluteMargin: '-',
      haflaPrice: parseFormatPriceValueFromAPI(listedDiscountAmount),
      margin: '-',
      partnerPrice: '-',
      rowTitle: 'Item Discounts'
    },
    {
      absoluteMargin: parseFormatPriceValueFromAPI(
        sellingPriceAmount - partnerPreVATAmount
      ),
      haflaPrice: parseFormatPriceValueFromAPI(sellingPriceAmount),
      margin: getFormattedMarginPercent(
        partnerPreVATAmount,
        sellingPriceAmount
      ),
      partnerPrice: parseFormatPriceValueFromAPI(partnerPreVATAmount),
      rowTitle: 'Item Total'
    },
    {
      absoluteMargin: parseFormatPriceValueFromAPI(deliveryChargeAmount - 0.0),
      dbName: 'deliveryChargeAmount',
      haflaPrice: parseFormatPriceValueFromAPI(deliveryChargeAmount),
      margin: getFormattedMarginPercent(0.0, deliveryChargeAmount),
      mode: priceSummaryViewEditMode,
      partnerPrice: '0.00',
      rowTitle: 'Delivery Charges'
    },
    {
      absoluteMargin: parseFormatPriceValueFromAPI(
        additionalChargeAmount - 0.0
      ),
      dbName: 'additionalChargeAmount',
      haflaPrice: parseFormatPriceValueFromAPI(additionalChargeAmount),
      margin: getFormattedMarginPercent(0.0, additionalChargeAmount),
      mode: priceSummaryViewEditMode,
      partnerPrice: '0.00',
      rowTitle: 'Additional Charges'
    },
    {
      absoluteMargin: '-',
      haflaPrice:
        parseFormatPriceValueFromAPI(promoCodeDiscountAmount) || '0.00',
      margin: '-',
      partnerPrice: '-',
      rowTitle: 'Promo Discount'
    },
    {
      absoluteMargin: '-',
      dbName: 'lumpSumDiscount',
      haflaPrice: parseFormatPriceValueFromAPI(lumpSumDiscount),
      margin: '-',
      mode: priceSummaryViewEditMode,
      partnerPrice: '-',
      rowTitle: 'Bulk Discount'
    },
    {
      absoluteMargin: parseFormatPriceValueFromAPI(
        preVatAmount - partnerPreVATAmount
      ),
      haflaPrice: parseFormatPriceValueFromAPI(preVatAmount),
      margin: getFormattedMarginPercent(partnerPreVATAmount, preVatAmount),
      partnerPrice: parseFormatPriceValueFromAPI(partnerPreVATAmount),
      rowTitle: 'Pre-VAT Total'
    },
    {
      absoluteMargin: '-',
      haflaPrice: parseFormatPriceValueFromAPI(vatAmount),
      margin: '-',
      partnerPrice: parseFormatPriceValueFromAPI(partnerVATAmount),
      rowTitle: 'VAT Amount'
    },
    {
      absoluteMargin: '-',
      dbName: 'nonTaxableCharge',
      haflaPrice: parseFormatPriceValueFromAPI(nonTaxableCharge),
      margin: '-',
      mode: priceSummaryViewEditMode,
      partnerPrice: '-',
      rowTitle: 'Other Charges (non-taxable)'
    }
  ];

  // TODO: Rajesh/Tanmay - planner-cart-v1 - to compose new API for cart update by planner, as the API in use is not having auth wrapper
  const saveChanges = (updatedCartDetails) => {
    const cartAttributesToUpdate = {
      lumpSumDeliveryCharge: parsePriceValueForAPI(
        Number(updatedCartDetails.deliveryChargeAmount) -
          parsedDeliveryChargeAmount
      ),
      lumpSumAdditionalCharge: parsePriceValueForAPI(
        Number(updatedCartDetails.additionalChargeAmount) -
          parsedAdditionalChargeAmount
      ),
      lumpSumDiscount: parsePriceValueForAPI(
        Number(updatedCartDetails.lumpSumDiscount)
      ),
      nonTaxableCharge: parsePriceValueForAPI(
        Number(updatedCartDetails.nonTaxableCharge)
      )
    };
    updateCartDetailAPI({ ...cartAttributesToUpdate, id });
    setPriceSummaryViewEditMode(VIEW_EDIT_MODE.VIEW);
  };

  const onClickEdit = () => setPriceSummaryViewEditMode(VIEW_EDIT_MODE.EDIT);

  const onClickDiscardChanges = () =>
    setPriceSummaryViewEditMode(VIEW_EDIT_MODE.VIEW);

  return (
    <div>
      <div className='my-4'>
        <CartSectionHeading
          {...{
            isCartEditable,
            mode: priceSummaryViewEditMode,
            onClickDiscardChanges,
            onClickEdit,
            onClickSaveChanges: handleSubmit(saveChanges),
            title: 'Summary'
          }}
        />
        <UnSavedChangesErrorMessage {...{ unSavedChangesErrorMessage }} />
      </div>
      <div className=' border border-platinum rounded-lg mb-4'>
        <PriceSummaryHeader
          {...{ priceSummaryHeader: PRICE_SUMMARY_HEADERS }}
        />
        {summaryItems.map(
          (
            {
              absoluteMargin,
              dbName,
              haflaPrice,
              margin,
              mode,
              partnerPrice,
              rowTitle
            },
            index
          ) => (
            <PriceSummaryRow
              key={index}
              {...{
                absoluteMargin,
                dbName,
                getValues,
                haflaPrice,
                margin,
                mode,
                partnerPrice,
                register,
                rowTitle
              }}
            />
          )
        )}
        <PriceSummaryFooter
          {...{
            absoluteMargin: parseFormatPriceValueFromAPI(
              orderTotal - partnerAmountWithVAT
            ),
            grandTotal: 'Grand Total',
            haflaPrice: parseFormatPriceValueFromAPI(orderTotal),
            margin: getFormattedMarginPercent(partnerAmountWithVAT, orderTotal),
            partnerPrice: parseFormatPriceValueFromAPI(partnerAmountWithVAT)
          }}
        />
      </div>
    </div>
  );
};

export default CartPriceSummary;
