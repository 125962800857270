import Image from 'next/image';

import {
  ApplyForCreditBanner,
  HostCreditEntityCreditUtilization,
  LetsPlanFirstEvent
} from '@/components/atomic/atoms';
import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getCreditEntityStatisticsParamaters } from '@/services/hostCreditEntity.service';

const LifeTimeGEV = ({ grossUserEventValue }) => (
  <TextKeyValuePair
    {...{
      className:
        'flex justify-between w-full bg-brand-gradient py-3 px-4 rounded-lg text-white w-full',
      label: 'Life Time GEV',
      labelClass: 'font-medium text-sm',
      value: grossUserEventValue,
      valueClassName: 'font-semibold'
    }}
  />
);

const IconWithTextKeyValuePair = ({ alt, icon, label, value }) => (
  <div className='flex items-center gap-2'>
    <Image
      alt={alt}
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${icon}`}
      style={{ height: 28, width: 28 }}
      width={0}
    />
    <TextKeyValuePair
      {...{
        className: 'flex flex-col !gap-0',
        label: value,
        labelClass: 'text-xl text-brand font-semibold',
        value: label,
        valueClassName: 'text-xs font-medium text-nero'
      }}
    />
  </div>
);

const HostCreditEntityEventStatistics = ({ eventStatistics }) => {
  const {
    averageMarginPercent,
    averageUserEventValue,
    grossUserEventValue,
    userEventCount,
    userEventsConverted
  } = getCreditEntityStatisticsParamaters({
    eventStatistics
  });

  const hostCreditEntityEventStatisticsList = [
    userEventsConverted,
    averageMarginPercent,
    averageUserEventValue
  ];

  return (
    <div className='flex flex-col gap-4 w-full'>
      <div className='bg-entity border border-neutral bg-no-repeat flex flex-1 flex-col bg-cover rounded-xl'>
        <div className='flex flex-col gap-4 justify-center items-center py-4 px-6'>
          <Text
            {...{
              className: 'font-semibold text-xl text-brand',
              content: 'Here’s The Hafla Summary'
            }}
          />
          <LifeTimeGEV {...{ grossUserEventValue }} />
        </div>
        <div className='flex gap-8 px-6 pb-6 pt-4'>
          <TextKeyValuePair
            {...{
              className:
                'bg-total-event w-[140px] h-[140px] flex flex-col px-4 text-center justify-center items-center gap-0',
              label: userEventCount.value,
              labelClass: 'text-brand font-semibold text-[28px] leading-6',
              value: userEventCount.label,
              valueClassName: 'text-sm text-nero font-medium leading-4'
            }}
          />
          <div className='flex gap-4 flex-col'>
            {hostCreditEntityEventStatisticsList.map((item, index) => (
              <IconWithTextKeyValuePair
                key={index}
                {...item}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const HostCreditEntitySideBarBody = ({
  applyForCredit,
  createdAt,
  eventStatistics,
  hasCreditLimit,
  letsPlanFirstEvent: { show: showLetsPlanFirstEvent, labelClassName }
}) => (
  <div className='w-[27rem]'>
    {showLetsPlanFirstEvent ? (
      <LetsPlanFirstEvent
        {...{
          createdAt,
          labelClassName
        }}
      />
    ) : (
      <HostCreditEntityEventStatistics
        {...{
          createdAt,
          eventStatistics
        }}
      />
    )}
    {hasCreditLimit ? (
      <HostCreditEntityCreditUtilization />
    ) : (
      <ApplyForCreditBanner {...{ onClick: applyForCredit }} />
    )}
  </div>
);

export default HostCreditEntitySideBarBody;
