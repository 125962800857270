import { EPMFormRow } from '@/components/atomic/atoms';
import { InputField } from '@/components/atomic/nuclei';
import { EVENT_PROFILE_FORM_STEPS } from '@/services/hostEvent.service';
import { checkIsEmptyOrDummyEventDetail } from '@/services/userEventCartList.service';

const getCustomerTypeAndPhone = ({
  errors,
  isUserEventDetailInEditMode,
  register
}) => [
  {
    className: 'rounded-lg pl-10',
    dbName: 'customerSegment.name',
    disabled: true,
    errors,
    iconURL: isUserEventDetailInEditMode
      ? 'user-red-with-frame.svg'
      : 'user-icon-black.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Host Type',
    labelClass: 'text-nero text-base font-medium',
    placeholder: 'Enter Host Type',
    register: register('customerSegment.name')
  },
  {
    className: 'rounded-lg pl-10',
    dbName: 'mobile',
    disabled: true,
    errors,
    iconURL: isUserEventDetailInEditMode
      ? 'call-with-frame.svg'
      : 'phone-icon-black.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Phone Number *',
    labelClass: 'text-nero text-base font-medium',
    placeholder: 'Enter Phone Number',
    register: register('mobile')
  }
];

const HostProfileStep = ({
  errors,
  formActiveStepId,
  getValues,
  isUserEventDetailInEditMode,
  register
}) => {
  const getErrorClass = ({ key }) =>
    checkIsEmptyOrDummyEventDetail({
      key,
      value: getValues(key)
    })
      ? '!text-error-base'
      : '';

  const formStepVisibilityClass =
    formActiveStepId === EVENT_PROFILE_FORM_STEPS.HOST_PROFILE.id
      ? 'block'
      : 'hidden';

  return (
    <div
      className={formStepVisibilityClass}
      id={EVENT_PROFILE_FORM_STEPS.HOST_PROFILE.id}
    >
      <div className='flex flex-col gap-4 px-8'>
        <InputField
          {...{
            className: `rounded-lg pl-10 ${getErrorClass({ key: 'eventContactName' })}`,
            dbName: 'eventContactName',
            disabled: !isUserEventDetailInEditMode,
            errors,
            iconURL: isUserEventDetailInEditMode
              ? 'user-red-with-frame.svg'
              : 'user-icon-black.svg',
            inputGroup: 'flex flex-col gap-2',
            label: 'Host Name *',
            labelClass: 'text-nero text-base font-medium',
            placeholder: 'Enter host name',
            register: { ...register('eventContactName') }
          }}
        />
        <EPMFormRow
          {...{
            inputProps: getCustomerTypeAndPhone({
              errors,
              isUserEventDetailInEditMode,
              register
            })
          }}
        />

        <InputField
          {...{
            className: `rounded-lg pl-10 ${getErrorClass({ key: 'eventContactEmail' })}`,
            dbName: 'eventContactEmail',
            disabled: !isUserEventDetailInEditMode,
            errors,
            iconURL: isUserEventDetailInEditMode
              ? 'email-icon.svg'
              : 'email-icon-black.svg',
            inputGroup: 'flex flex-col gap-2',
            label: 'Host Email *',
            labelClass: 'text-nero text-base font-medium',
            placeholder: 'Enter Host email',
            register: { ...register('eventContactEmail') }
          }}
        />
        <InputField
          {...{
            className: 'rounded-lg pl-10',
            dbName: 'hostCreditEntity.name',
            disabled: true,
            errors,
            iconURL: isUserEventDetailInEditMode
              ? 'company-icon.svg'
              : 'company-icon-black.svg',
            inputGroup: 'flex flex-col gap-2',
            label: 'Credit Entity Name',
            labelClass: 'text-nero text-base font-medium',
            placeholder: 'Enter Credit Entity Name',
            register: { ...register('hostCreditEntity.name') }
          }}
        />
        <div className='flex w-1/2'>
          <InputField
            {...{
              className: 'rounded-lg pl-10',
              dbName: 'trnNumber',
              disabled: !isUserEventDetailInEditMode,
              errors,
              iconURL: isUserEventDetailInEditMode
                ? 'trn-icon-red.svg'
                : 'trn-icon-black.svg',
              inputGroup: 'flex flex-col gap-2 flex-1',
              label: 'TRN',
              labelClass: 'text-nero text-base font-medium',
              placeholder: 'Enter TRN',
              register: { ...register('trnNumber') }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HostProfileStep;
